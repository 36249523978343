

const LaptopLogin = () => {
  return (
    <>
      <button className="hover:text-green-400    w-full py-2.5 px-8 lg:block hidden text-[0.8rem] capitalize whitespace-nowrap bg-blue-600 max-w-[114px] text-white  rounded-11xl ">
        Login
      </button>
    </>
  )
}

export default LaptopLogin
