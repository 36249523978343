import React from 'react'

const Location = () => {

  const googleMapsApiKey = 'YOUR_GOOGLE_MAPS_API_KEY';
  const address = 'A-1/47, 1st Floor, Ajad Hind Fauj Marg (Sewak Park), Dwarka Mor, New Delhi - 110059, India';

  const parentDivStyle = {
      width: '100%', // Adjust the width of the parent div
      height: '400px', // Adjust the height of the parent div
  };

  const iframeStyle = {
      width: '100%',
      height: '100%',
      border: '0',
  };



  return (
    <div className='md:mx-4 mx-2  pt-16'>
    <div className='font-dm-sans ml-cust-10  mr-cust-10 '>
        <div className='flex justify-center mb-10'>
            <div className='flex flex-col gap-2 items-center'>
                <div className=" w-[100px]  flex justify-center bg-white rounded-51xl box-border  text-center    py-2  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
                  <div className="text-center  font-medium text-sm font-['DM Sans']  leading-tight">Location</div>
                </div>
                <div className="text-[2.4rem] md:text-[3rem] max-sm:text-[1.8rem]  text-center text-black  font-medium font-['DM Sans'] capitalize">You can find us here</div>
                <div className="text-black text-opacity-60 text-[10px] md:text-base font-medium font-['DM Sans'] capitalize leading-loose">reach out to us at our office well help you</div>
            </div>
          
        </div>

        <div className='grid  md:grid-cols-2 xl:grid-cols-3 gap-6'>

                    <div className="flex pl-6 lg:pl-10 pr-6 max-sm:pl-0  items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className="flex flex-col gap-8 pl-4 pr-4 py-8">
                            
                            <div className='flex gap-4 '>
                              <span className='bg-[#3460FF] px-2  flex rounded-md items-center'>
                                <img className='' src="Rectangle-517.svg" alt="img" />
                              </span>
                              <div className="text-center text-black text-[23px] font-bold font-['DM Sans'] capitalize">Visit Us</div>
                            </div>

                            <div className="flex mt-2 flex-col gap-2">
                              <div className=" text-black text-[15px] font-bold font-['DM Sans'] capitalize">Head Office</div>
                              <div className=" text-black text-opacity-70 text-[15px] font-medium font-['DM Sans'] leading-loose">A-1/47, 1st Floor, Ajad Hind Fauj Marg (Sewak Park),Dwarka Mor, New Delhi - 110059, India</div>
                            </div>


                            <div className="flex flex-col gap-2">
                              <div className=" text-black text-[15px] font-bold font-['DM Sans'] capitalize">Branch Office</div>
                              <div className=" text-black text-opacity-70 text-[15px] font-medium font-['DM Sans'] leading-loose">H. No. 215, Chotti Bazar Near Ai Ismail Complex,
Karan Nagar, Srinagar, J & K - 190010, (India)</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className="" alt="img" src="call2.svg" />
                                    <div className=" leading-tight text-[15px] font-medium">+91 9899 8610 71</div>
                                </div>
                                
                                

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">info@avags.in</div>
                    
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className="" alt="img" src="call2.svg" />
                                    <div className=" leading-tight text-[15px] font-medium">011-41077955</div>
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div className='xl:col-span-2'>
                    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.447837302299!2d77.0299056255002!3d28.616337125673525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0537cff51e59%3A0x878358f218d90aa0!2sAvags%20Information%20Systems!5e0!3m2!1sen!2sin!4v1699071123384!5m2!1sen!2sin"
      width="100%"
      height="100%"
      style={{ border: '0' }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
                    </div>

        </div>
    </div>
    </div>
  )
}

export default Location
