
import Header from '../components/FreeTrail/Header';

import TrailForm from '../components/FreeTrail/TrailForm';
import FooterCust from '../components/HomePage/FooterCust';

const FreeTrail = () => {
  return (
    <div>
       
      <Header/>
      <TrailForm/>

    
     
      <div className=" overflow-hidden mt-20 text-white font-dm-sans ">
          <FooterCust/>
      </div>

    </div>
  )
}

export default FreeTrail;
