import React from 'react'

import HeaderC from '../components/Contact/HeaderC';
import FooterCust from '../components/HomePage/FooterCust';
import Form from '../components/Contact/Form';
import Regional from '../components/Contact/Regional';
import Location from '../components/Contact/Location';

const Contact = () => {
  return (
    <div>
        <HeaderC/>
        <Location/>
        <Regional/>
        <Form/>
        <div className="overflow-hidden mt-20 text-white font-dm-sans ">
          <FooterCust/>
        </div>

    </div>
  )
}

export default Contact;
