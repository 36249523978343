import { Link } from "react-router-dom";

const LoginFooter = () => {
  return (
    <div className='grid lg:grid-cols-4 grid-cols-1 '>
      
      <div className='pt-16 pb-12 lg:col-span-3 flex flex-col px-[20%] md:px-0 bg-midnightblue md:grid md:grid-cols-5 grid-cols-2 md:gap-10 gap-14'>
        
      <div className=' md:pl-6 xl:pl-10 flex flex-col gap-9'>
            <b className=" pb-2 md:pb-0 md:text-[12px] xl:text-[14px] md:min-h-[70px] h-min-[80px] text-[12px] leading-loose">
            	HEALTH SCIENCE
            </b>
            <Link className='text-white no-underline'  to={`/services/${'medical'.replaceAll(' ', '-')}`}
                             ><div className=' leading-[1.25rem] text-slate-300 font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS] '>DVL Medical</div></Link>
             <Link   className='text-white no-underline'   to={`/services/${'dental'.replaceAll(' ', '-')}`}
                                            ><div className=' leading-[1.25rem] text-slate-300 font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS]'>DVL Dental</div></Link>
            <Link className='text-white no-underline'  to={`/services/${'nursing'.replaceAll(' ', '-')}`}
                              > <div className=' leading-[1.25rem] text-slate-300 font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS]'>DVL Nursing</div></Link>
            <Link className='text-white no-underline'  to={`/services/${'pharmacy'.replaceAll(' ', '-')}`}
                              > <div className=' leading-[1.25rem]  text-slate-300 font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS]'>DVL Pharmacy</div></Link> 
           
           <Link className='text-white no-underline'  to={`/services/${'Allied Health & Paramedics'.replaceAll(' ', '-')}`}
                              >   <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Allied Health & Paramedics</div></Link> 
            <Link className='text-white no-underline'  to={`/services/${'Ayush & Yoga Science'.replaceAll(' ', '-')}`}
                            ><div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Ayush & Yoga Science</div></Link>  
            
            <Link className='text-white no-underline'  to={`/services/${'Basic & Applied Science'.replaceAll(' ', '-')}`}
                             > <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Basic & Applied Science</div></Link>
            
            <Link className='text-white no-underline'   to={`/services/${'homeopathy'.replaceAll(' ', '-')}`}
                              >  <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Homeopathy</div></Link>
 
 <Link className='text-white no-underline'  to={`/services/${'DNB'.replaceAll(' ', '-')}`}
                              ><div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL DNB</div></Link>
           <Link className='text-white no-underline'  to={`/services/${'physiotherapy'.replaceAll(' ', '-')}`}
                             >  <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Physiotherapy</div></Link>
     
        </div>
        
        <div className='flex flex-col  gap-9'>
            <b className="md:text-[12px] pb-2 md:pb-0  xl:text-[14px] md:min-h-[70px] h-min-[80px] text-[12px] leading-loose">
            SCIENCE TECHNOLOGY & MANAGEMENT
            </b>
            <Link className='text-white no-underline'  to={`/services/${'engineering'.replaceAll(' ', '-')}`}
                    > <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS] text-slate-300'>DVL Engineering</div></Link>
              <Link className='text-white no-underline'  to={`/services/${'management'.replaceAll(' ', '-')}`}
                      ><div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Management</div></Link>
           
        </div>

        <div className=' md:pl-0  flex flex-col gap-9'>
            <b className="md:text-[12px] pb-2 md:pb-0 xl:text-[14px] md:min-h-[70px] h-min-[80px] text-[12px] leading-loose">
            LEGAL STUDIES
            </b>
           
            <Link className='text-white no-underline'  to={`/services/${'law'.replaceAll(' ', '-')}`}
                    ><div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Law</div></Link> 
            <Link className='text-white no-underline'  to={`/services/${'Forensic & Legal Studies'.replaceAll(' ', '-')}`}
                      >  <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Forensic & Legal Studies</div></Link>
        </div>
       
        <div className=' flex flex-col gap-9 px-1'>
            <b className="md:text-[12px] pb-2 md:pb-0  xl:text-[14px] md:min-h-[70px] h-min-[80px] text-[12px] leading-loose">
            ART, SOCIAL SCIENCE & AGRICULTURE
            </b>
            <Link className='text-white no-underline'  to={`/services/${'agriculture'.replaceAll(' ', '-')}`}
       > <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 font-[DM SANS] text-slate-300'>DVL Agriculture</div></Link>
            <Link className='text-white no-underline'  to={`/services/${'architecture'.replaceAll(' ', '-')}`}
       > <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Architecture</div></Link>
               <Link className='text-white no-underline'  to={`/services/${'education'.replaceAll(' ', '-')}`}
      >          
        <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Education</div></Link>
             <Link className='text-white no-underline'  to={`/services/${'Humanity & Social Science'.replaceAll(' ', '-')}`}  >    <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Humanities & Social Science</div></Link>
              
        <Link className='text-white no-underline' to={`/services/${'Mass Communication & Media'.replaceAll(' ', '-')}`} 
       >   <div className=' leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px] hover:text-green-600 text-slate-300'>DVL Mass Communication & Media</div></Link>
        </div>
        
       
    

        <div className='md:pl-0 pr-6 md:col-span-1 col-span-2 flex flex-col gap-9'>
            <b className="pb-2 md:pb-0 md:text-[12px] xl:text-[14px] md:min-h-[70px]  text-[12px]  leading-[1.25rem]">
            Reach Us
            </b>
            <div className='flex md:gap-2 xl:gap-4  gap-4  leading-[1.25rem] font-medium xl:text-[0.8rem] text-[13px] md:text-[10px] font-[DM SANS] text-slate-300'>
                <div className='pt-1'>
                  <img src="location.svg" alt="img" />
                </div>  
               <div style={{ wordWrap: 'break-word' }}>
                A-1/47, 1st Floor, Ajad Hind Fauj Marg (Sewak Park), Dwarka Mor, New Delhi - 110059, India
               </div>
            </div>
            <div className='flex md:gap-2 xl:gap-4  gap-4 leading-[1.25rem] font-medium xl:text-[0.88rem] text-[13px] md:text-[10px]  text-slate-300'>
                <div className='pt-1'>
                  <img src="msg.svg" alt="img" />
                </div> 
                <div style={{ wordWrap: 'break-word' }} className='flex flex-col gap-1'>
                  <div className="font-bold text-green-400">For Sales:</div>
                  <div className="flex flex-col gap-3">
                  <div className="mt-2">Info@avags.in </div>
                  <div className="">   011-4107 7955 </div>
                  <div className="">+91 9899 8610 71 </div>
                  </div>
            
                 

                  <div  className="mt-8 font-bold text-green-400">For Tech Support:  </div>
                  <div className="flex flex-col gap-3 mt-2">
                  <div className=''>diksha@avags.in</div>
                  <div className=''>avags.it@gmail.com</div>
                  <div className=''>+91 9319 7011 12</div>
                  </div>
             
                </div>
            </div>
            
            
        </div>

      </div>
      <div className="lg:col-span-4 md:gap-0 gap-8 py-6 w-full  bg-slate-950  md:justify-between justify-center items-center flex md:flex-nowrap flex-wrap">
        <div className="pl-10 mr-10   text-slate-300 text-sm font-medium font-['DM Sans'] leading-tight">© 2023 Website hosted and managed by AVAGS Inc. - All Rights Reserved</div>


        <div className="md:pl-0 pl-10  bg-slate-950  mr-10 md:gap-8 gap-3 items-center flex">
          <Link className='no-underline text-white' to='/terms'><div className="text-slate-300 hover:text-green-600 text-sm font-medium font-['DM Sans'] leading-tight">Terms and Conditions</div></Link>
          <Link className='no-underline text-white' to='/Privacy'><div className="text-slate-300 hover:text-green-600 text-sm font-medium font-['DM Sans'] leading-tight">Privacy Policy</div> </Link>
        
          <Link className='no-underline text-white' to='/contact'><div className="text-slate-300 hover:text-green-600 text-sm font-medium font-['DM Sans'] leading-tight">Contact Us </div></Link>
        
        </div>
      </div>
    </div>
  )
}

export default LoginFooter
