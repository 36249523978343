
import axios from 'axios';

import { questions } from "../../constants";
import {useState} from 'react';
import { Link } from "react-router-dom";
import PopupMessage from './PopupMessage ';
const TrailForm = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    enquirySubject: '',
    enquiry: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async  (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // If the submission is successful, update the state
    try {
      const response = await axios.post('YOUR_SERVER_ENDPOINT', formData);
  
      // Handle successful response
      console.log('Form data sent successfully:', response.data);
  
      // If the submission is successful, update the state
      setIsFormSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      
    }
    setIsFormSubmitted(true);
  };

  const closePopup = () => {
    setIsFormSubmitted(false);
  };


  return (
    <div className="text-black font-dm-sans  mt-20">
        {isFormSubmitted && (
        <PopupMessage
          message="Thank you for filling out the form!"
          onClose={closePopup}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )}
        <div className={` ${
          isFormSubmitted ? 'blur-sm' : ''
        } ml-cust-10 gap-4 mr-cust-10  grid grid-cols-1 md:grid-cols-2`}>

              <div className="md:w-11/12  flex flex-col ">
          
                <div className="mb-6 w-[90px] flex justify-center bg-white rounded-51xl box-border  text-center    py-1  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
                  <div className="text-center  font-medium  font-['DM Sans']  leading-tight">Form</div>
                </div>

                <div class="text-[2.2rem] max-sm:text-[1.4rem] font-[550]  tracking-[-1px] md:text-[2rem] capitalize md:font-medium text-black">Book Free Trial Now</div>
                  
                <div className="text-[1rem]   pt-5 leading-[2rem] font-medium text-gray-1000 ">
                Fill out the form and our representatives will reach out to very soon.
                </div>
                <div className="text-[1.13rem] mt-10 capitalize font-medium">Our Tech Support Team</div>
               
                <div className="flex gap-10 mt-8 flex-wrap">
                    <div className="flex gap-2 items-center">
                      <img className=" " alt="img" src="/group-427318208.svg"/>
                      <div className=" leading-[1.25rem] font-medium">avags.it@gmail.com</div>
  
                    </div>

                    <div className="flex gap-2 items-center">
                      <img className=" " alt="img" src="/group-427318208.svg"/>
                      <div className=" leading-[1.25rem] font-medium">diksha@avags.in</div>
  
                    </div>


                    <div className="flex gap-2 items-center">
                      <img className=" " alt="img" src="call2.svg"/>
                      <div className=" leading-[1.25rem] font-medium">+91 9319 7011 12</div>
  
                    </div>

                </div>


             
                <div class="p-0 m-0">
                    <img class=" w-full relative xl:left-[-2.69rem] lg:left-[-2rem] md:left-[-1.3rem] left-[-1.6rem] max-sm:left-[-1rem]  max-w-full" alt="img" src="/rectangle-197@2x-1.png" />
                </div>
  
              </div>

            <div className=" ">
                <form onSubmit={handleFormSubmit} className="">
                    <div className="hidden md:flex pl-1 flex-nowrap gap-2  ">
                        <div className=" tracking-[0.01em]   leading-[100%] font-medium text-[0.88rem] w-1/2">First Name</div>
                        <div className="tracking-[0.01em]   leading-[100%] font-medium text-[0.88rem]  w-1/2">Last Name</div>
                    </div>
                    <div className="hidden md:flex flex-nowrap gap-2 mt-2">
                        <input 
                                className="md:pl-4 py-4 w-1/2 bg-[#F7FBFF] placeholder-[#AAAAAA]  font-medium font-[DM Sans] leading-tight box-border border-round-custom rounded-11xl p-2"
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                placeholder="Enter First Name"
                                required
                        />
                        <input 
                                className="md:pl-4 py-4 w-1/2 bg-[#F7FBFF] placeholder-[#AAAAAA]  font-medium font-[DM Sans] leading-tight box-border border-round-custom rounded-11xl p-2"
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                placeholder="Enter Last Name"
                                required
                        /> 
                    </div>

                    <div class="block md:hidden  tracking-[0.01em] pl-1 mt-8 leading-[100%] font-medium text-[0.88rem]">First Name</div>
                    <div className="block md:hidden mt-2  flex-nowrap gap-2">
                        <input 
                                    className="md:pl-4 py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA]  font-medium font-[DM Sans] leading-tight box-border border-round-custom rounded-11xl p-2"
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    placeholder="Enter First Name"
                                    required
                          />
                      
                    </div>


                    <div class="block md:hidden tracking-[0.01em] pl-1 mt-8 leading-[100%] font-medium text-[0.88rem]">Last Name</div>
                    <div className="block md:hidden  mt-2  flex-nowrap gap-2">
                        <input 
                                className="md:pl-4 py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA]  font-medium font-[DM Sans] leading-tight box-border border-round-custom rounded-11xl p-2"
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                placeholder="Enter Last Name"
                                required
                        /> 
                      
                    </div>


                    <div class="tracking-[0.01em] pl-1 mt-8 leading-[100%] font-medium text-[0.88rem]">Email Id</div>
                    <div className="mt-2 flex flex-nowrap gap-2">
                        <input 
                                className="md:pl-4  py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter Email Id"
                                required
                        />
                      
                    </div>

                    <div class="tracking-[0.01em] pl-1  mt-8 leading-[100%] font-medium text-[0.88rem]">Phone Number</div>
                    <div className="mt-2 flex flex-nowrap gap-2">
                      


                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          required
                          value={formData.phone}
                          onChange={handleInputChange}
                          placeholder="Enter your phone number"
                          className="md:pl-4  py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                                  />
                      
                    </div>


                    <div class="tracking-[0.01em] pl-1 mt-8 leading-[100%] font-medium text-[0.88rem]">Enquiry Subject</div>
                    <div className="mt-2 flex flex-nowrap gap-2">
                        <input 
                                className="md:pl-4  py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                                type="text"
                                required
                                name="enquirySubject"
                                value={formData.enquirySubject}
                                onChange={handleInputChange}
                                placeholder="Write your enquiry subject here"
                        />
                      
                    </div>

                    <div class="tracking-[0.01em] pl-1 mt-8 leading-[100%] font-medium text-[0.88rem]">Enquiry</div>
                    <div className="h-[149px] mt-2  ">

                        <textarea 
                          className="md:pl-4 md:pt-4 resize-y h-[149px] w-full bg-[#F7FBFF] placeholder-[rgb(170,170,170)] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-md p-2"
                          type="text"
                          name="enquiry"
                          value={formData.enquiry}
                          onChange={handleInputChange}
                          placeholder="Write your enquiry subject here"
                          required
                        />     
                    </div>
                    <div className="flex items-start mt-4 gap-1">
                        <input type="checkbox" id="roundCheckbox" className="mt-1" required/>
                        <div className='text-black text-opacity-60 text-[0.8rem] font-medium font-dm-sans leading-[21px]'>
                        By providing your contact information and requesting a consultation, you agree to AVAGS 
                        <Link className="text-white no-underline" to="/privacy"><span className="text-mediumslateblue-100 "> Privacy Statement </span></Link> and 
                        <Link className="text-white no-underline" to="/terms"><span className="text-mediumslateblue-100"> Terms of Use</span></Link>
                        </div>
                    </div>

                    <button type="submit"
                            className="mt-3 w-full whitespace-nowrap bg-mediumslateblue-200 max-w-[114px] text-white lg:h-[40px] font-medium font-[DM Sans] leading-tight border border-mediumslateblue-500  rounded-11xl p-2"
                            >
                            Send
                        </button>
                   
                </form>
            </div>
        </div>
        
    </div>
  )
}

export default TrailForm
