import NavbarCust from "../components/HomePage/NavbarCust";
import { Element } from 'react-scroll';

import AboutUs from "../components/HomePage/AboutUs";

import DailyBlogs from "../components/HomePage/DailyBlogs";
import Footer from "../components/HomePage/Footer";
import FooterCust from "../components/HomePage/FooterCust";
import FreeTrail from "../components/HomePage/FreeTrail";
import Navbar from "../components/HomePage/Navbar";

import OurCommitment from "../components/HomePage/OurCommitment";
import Slider from "../components/HomePage/Slider";
import Testimonial from "../components/HomePage/Testimonial";
import Trail from "../components/HomePage/Trial";



const Home = () => {
  return (
    <div className=" bg-white w-full  overflow-hidden text-left text-[1rem] text-white font-dm-sans">
      
      

     
      <NavbarCust/>
      
      <AboutUs/>
      <Element name="resources" className="element">
        <OurCommitment/></Element>
      <Slider/>
      <Testimonial/>
      <Trail/>
      <Element name="blogs" className="element"><DailyBlogs/></Element>
      
      
      <FooterCust/>

    </div>
  );
};

export default Home;
