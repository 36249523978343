
import { useEffect, useState } from "react";
import Mycard from "./Mycard.jsx";
import './Slider.css';
import {abouts} from '../../constants.js'






const Slider = () => {

  const {Mass,
    Humanity,
    education,
    agriculture,
    architecture,
    Forensic,
    law,
    management,
    engineering,
    physiotherapy,
    DNB,
    Basic,
    Ayush,
    Allied,
    pharmacy,
    nursing,
    dental,
    medical,
    homeopathy}=abouts;
 const totalPages=16;

  // Create an array of page numbers for pagination
  const pageNumbers = Array.from({ length: 16 }, (_, index) => index + 1);

  const [currentPage, setCurrentPage] = useState(1); // Initialize current page
  

  const handlePageChange = (page) => {
  // Set the current page directly
  setCurrentPage(page);

  // Scroll to the corresponding item using smooth behavior
  const childwidth = childbox.clientWidth;
  const scrollPosition = (page - 1) * (childwidth + 32);

  //console.log('page',scrollPosition);

  // Scroll to the corresponding item
  box.scrollTo({
    left: scrollPosition,
    behavior: "smooth",
  });
};

  const [childbox,setChildBox]=useState({});
  const [box,setBox]=useState({});
  
  const maxVisiblePages = 4; // Maximum number of visible page numbers
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
  
  // Calculate the range of page numbers to display
  const startPage = Math.max(1, currentPage - halfMaxVisiblePages);
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  
  // Generate page numbers within the specified range
  const visiblePageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  

  const getBox=()=>{
    //console.log('inside getBox Testimonial');
     let box2 = document.querySelector('.product-container');
     let box3 = document.querySelector('.product-item');
     setBox(box2);
     setChildBox(box3);
  }

  useEffect(()=>{
    getBox();

  },[]);

 
  //console.log('box',box);
  const btnpressprev = () => {
      let width = box.clientWidth;
      let childwidth = childbox.clientWidth;
      //console.log('width',width);
      //console.log('childwidth',childwidth);
      box.scrollLeft = box.scrollLeft - childwidth-32;
      //console.log(width)
  }

  const btnpressnext = () => {
      let width = box.clientWidth;
      let childwidth = childbox.clientWidth;
      //console.log('width',width);
      //console.log('childwidth',childwidth);
      box.scrollLeft = box.scrollLeft + childwidth+32;
     // console.log(width)
  }



  return (
    <div className='bg-midnightblue flex  pb-24   mt-24'>
        <div className='mt-16  ml-cust-67 w-11/12 text-black'>
            <div className="px-6  max-mobile:mb-4 py-2 rounded-[70px] border-2 border-solid border-indigo-200  justify-center items-center gap-2.5 inline-flex">
              <div className="text-right text-indigo-200 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Services</div>
            </div>
            <div className="pt-4 text-[3.44rem] w-full">
                <div className='flex flex-wrap justify-between w-full'>     
                      <div className="text-white  min-breakpoint:text-[2.3rem] min-breakpoint:font-bold  text-[50px] font-medium font-['DM Sans'] capitalize">Your gateway to resources </div> 
                     
                </div>

                <div className="pt-1 text-white text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">
                    Check here all the resource categories we cater in our database
                </div>


                <div className=" max-mobile:mt-4 flex ">
                  <div className="ml-auto flex gap-3">
                                <div onClick={()=>{btnpressprev()}}  className="hover:bg-slate-400 hover:cursor-pointer flex justify-center items-center  h-16 w-16 bg-white border-[1px] border-solid border-slate-200 rounded-full " >
                                <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className="text-white text-[1rem] font-dm-sans ">
                                    <img src="bi_arrow-left-short-1.svg" alt="arrow" />
                                </span>
                                </div>
                            
                                <div onClick={()=>{btnpressnext()}}  className="hover:bg-blue-800  hover:cursor-pointer flex justify-center items-center h-16 w-16 bg-blue-900 rounded-full" >
                                    <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className=" text-white ">
                                        <img src="bi_arrow-left-short.svg" alt="arrow" />
                                    </span>
                                </div>
                  </div>
                       
                </div>
            </div>

            <div className="product-carousel">
             

              <div className="product-container max-sm:-ml-[3%] flex flex-nowrap  gap-8">
                  <div className="product-item"><Mycard state={{
                                titleState:'medical',
                                about: {medical},
                                indexing: [
                                  'Anatomy',
                                  'Biochemistry',
                                  'Community Medicine',
                                  'E.N.T. (Ear, Nose, Throat)',
                                  'Medicine',
                                  'Obstetrics & Gynecology',
                                  'Ophthalmology',
                                  'Pathology',
                                  'Pharmacology',
                                  'Physiology',
                                  'Radiology',
                                  'Anesthesia',
                                  'Cardiology',
                                  'Dermatology',
                                  'Forensic Medicine',
                                  'Microbiology',
                                  'Oncology',
                                  'Orthopedics',
                                  'Pediatrics',
                                  'Phonosurgery',
                                  'Psychiatric',
                                  'Surgery'
                                ],
                                resources: [3000     ,   900   ,  250   ,     2400],
                                img: `medical.jpg`
                              
                            }}  imgurl='medical.jpg' title="DVL Medical" content="An Integrated Bibliographic & Full Text Database Containing Citations, Abstracts And Full Text For Health & Medical Journals Used By Health Care Professionals, Nurses, Clinicians And Researchers." /></div>
                  <div className="product-item"><Mycard   state= {{
                                              titleState:'dental',
                                              about: {dental},
                                              indexing: [
                                                'Conservative & Endodontics',
                                                'Orthodontics & Dentofacial Orthopedics',
                                                'Prosthodontics',
                                                'Periodontology',
                                                'Oral Medicine & Radiology',
                                                'Oral Pathology',
                                                'Pedodontics',
                                                'Oral & Maxillofacial Surgery',
                                                'Public Health Dentistry',
                                                'Dentistry & Oral Science',
                                                'Basic Dental Science'
                                              ],
                                              resources: [700     ,   190    ,     300],
                                              img: `dental.jpg`
                                            
                                          }} imgurl='dental.jpg' title="DVL Dental" content="DVL Dental has been specially designed to meet the needs of dental research Community worldwide. It offers quick and easy access to an impressive aggregation of trusted content from Publisher." /></div>
                  <div className="product-item"><Mycard  state= {{
                                titleState:'nursing',
                                about:{nursing},
                                indexing: [
                                  'Community Health Nursing',
                                  'Gerontology And Geriatric Nursing',
                                  'Intensive Care Nursing',
                                  'Medical Science Nursing',
                                  'Medical Surgical Nursing',
                                  'Mental Health & Psychiatric Nursing',
                                  'Midwifery & Gynaecology',
                                  'Oncology-Palliative Care Nursing',
                                  'Paediatric Nursing',
                                  'Physical Medicine And Rehabilitation',
                                  'Public Health, Nutrition And Dietetics',
                                  'Radiology And Nuclear Medicine'
                                ],
                                resources: [440     ,   250   ,  50   ,     200],
                                img: `nursing.jpg`
                              
                            }} imgurl='nursing.jpg' title="DVL Nursing" content="DVL Nursing covers all subjects related to Its field. It provides content beyond Journals, which makes it a completed online library which caters all reqriement of students, faculties, researchers." /></div>
                  <div className="product-item"><Mycard state={{
                                titleState:'pharmacy',
                                about: {pharmacy},
                                indexing: [
                                  'Pharmaceutics',
                                  'Pharmacology & Toxicity',
                                  'Pharmacognosy & Methodology',
                                  'Pharmaceutical Chemistry',
                                  'Clinical Pharmacy',
                                  'Drug Discovery & Analysis',
                                  'Pharmaceutical Dosage Forms',
                                  'Hospital & Community Pharmacy',
                                  'Industrial Pharmacy',
                                  'IPR & Guidelines'
                                ],
                                resources: [450     ,   200   ,  50   ,     200],
                                img: `Pharmacy.jpg`
                              
                            }} imgurl='pharmacy.jpg' title="DVL Pharmacy" content="DVL Pharmacy is a platform which provides all the information required for research in Pharmacy. This database provides the access to 500+ full text journals and 100+ e-books covering all pharmacy subjects." /></div>
                 
                  <div className="product-item"><Mycard   state= {{
                                titleState:'Ayush-&-Yoga-Science',
                                about: {Ayush},
                                indexing: [
                                  'Agada Tantra And Vyavaharayurveda (Toxicology, Jurisprudence And Forensic Medicine)',
                                  'Dravyaguna (Ayurvedic Pharmacology)',
                                  'Kaumarabhritya (Paediatrics)',
                                  'Kayachikitsa (General Medicine)',
                                  'Kriya Sharir (Physiology)',
                                  'Maulika Siddhanta (Basic Principles Of Ayurveda)',
                                  'Naturopathy And Yoga',
                                  'Panchakarma',
                                  'Rachana Sharir (Anatomy)',
                                  'Rasashastra And Bhaishajya Kalpana (Medicinal Chemistry And Pharmacy)',
                                  'Roga Nidan & Vikriti Vigyan (Pathology)',
                                  'Shalya Tantra (Surgery)',
                                  'Stri Roga And Prasuti Tantra (Gynaecology, Obstetrics )',
                                  'Swasthyavritta (Social And Preventative Medicine)'
                                ],
                                resources: [200     ,   50  ,  100   ,     550],
                                img: `AYUSH.jpg`       
                            }} imgurl='Ayush.jpg' title="DVL Ayush & Yoga" content="DVL Ayush & Yoga is a platform which provides all the information required for research in ayush and yoga. This database provides the access to 180+ full text journals and 50+ e-books covering all  subjects." /></div>
                    <div className="product-item"><Mycard state= {{
                                titleState:'homeopathy',
                                about: {homeopathy},
                                indexing: [
                                  'Homoeopathic Pharmacy',
                                  'Materia Medica',
                                  'Bacteriology & Parasitology',
                                  'Anatomy',
                                  'Community Medicine',
                                  'Pathology',
                                  'Obstetrics & Gynaecology',
                                  'Physiology & Biochemistry',
                                  'Pediatrics Pathology',
                                  'Forensic Medicine & Toxicology',
                                  'Homoeopathic Philosophy & Psychology'
                                ],
                                resources: [300    ,   90  ,  100   ,     400],
                                img: `HOMEOPATHY.jpg`
                              
                            }} imgurl='homeopathy.jpg' title="DVL Homeopathy" content="DVL Homeopathy covering important journal published in this filed and offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-resources." /></div>
                  <div className="product-item"><Mycard  state= {{
                                titleState:'DNB',
                                about: {DNB},
                              
                                    indexing:[
                                      'Medicine',
                                      'Agricultural And Biological Sciences',
                                      'Anatomy',
                                      'Anesthesia',
                                      'Biochemistry',
                                      'Cardiology',
                                      'Community Medicine',
                                      'Dermatology',
                                      'E.N.T. (Ear, Nose, Throat)',
                                      'Forensic Medicine',
                                      'Medical',
                                      'Microbiology',
                                      'Obstetrics & Gynaecology',
                                      'Oncology',
                                      'Ophthalmology',
                                      'Orthopedics',
                                      'Pathology',
                                      'Pediatrics',
                                      'Pharmacology',
                                      'Physiology',
                                      'Psychiatric',
                                      'Radiology',
                                      'Surgery',
                                      'Tb Chest'
                                    ],
                                resources: [300     ,   900  ,  50  ,     400],
                                img: `DNB.jpg`
                              
                            }} imgurl='DNB.jpg' title="DVL DNB" content="DVL DNB has been specially designed to meet the need of research community worldwide. All the Indian journal in the specialty in which the accreditation is sought / subscribed for and preferably Two Foreign journals." /></div>
                 
                  <div className="product-item"><Mycard   state={{
                                titleState:'physiotherapy',
                                about:{physiotherapy},
                          
                                indexing: [
                                  'Basic Medical Science',
                                  'Cardio & Respiratory Disorders',
                                  'Exercise Therapy',
                                  'General Medicine And Surgery',
                                  'Musculoskeletal And Sports Physiotherapy',
                                  'Orthopaedics',
                                  'Pediatric And Adult Neuro-Rehabilitation',
                                  'Physiotherapy & Occupational Therapy',
                                  'Rehabilitation Science',
                                  'Sports Science And Medicine',
                                  'Vestibular Rehabilitation'
                                ],
                                resources: [170     ,   60  ,  80   ,     200],
                                img: `PHYSIOTHERAPY.jpg`
                              
                            }} imgurl='physiotherapy.jpg' title="DVL Physiotherapy" content="DVL Physiotherapy Provides a link to the content that resides on the publishers' Web platforms. Over 1,000+ records are currently available covering  sport, physical fitness, and the physical education content." /></div>
                  <div className="product-item"><Mycard   state={{
                        titleState:'engineering ',
                        about: {engineering},
                        indexing: [
                          'Biomedical Engineering',
                          'Chemical Technology',
                          'Civil Engineering',
                          'Computer Science',
                          'Electrical Engineering',
                          'Electronics Engineering',
                          'Environmental Engineering',
                          'General Engineering',
                          'Hydraulic Engineering',
                          'Materials Science',
                          'Mechanical Engineering',
                          'Nuclear Engineering',
                          'Production Engineering',
                          'Robotics',
                          'Tele-communication'
                        ],
                        resources: [1500  ,  2000  , 100 ,  650],
                        img: `Engineeringfinal.jpg`
                      
                    }} imgurl='engineering.jpg' title="DVL Engineering" content="DVL Engineering & Technology has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content." /></div>
                  <div className="product-item"><Mycard  state={{
                        titleState:'management',
                        about: {management},
                        indexing:[
                          'Banking',
                          'Computational Linguistics',
                          'Computational Optimization',
                          'Computational Simulation And Modeling',
                          'E-Commerce',
                          'Economics And Business Management',
                          'Environment Management',
                          'Finance And Accounting',
                          'General Management',
                          'Hospitality & Tourism Management',
                          'Industry And Industrial Studies',
                          'Information Technology',
                          'Marketing Management',
                          'Social Studies'
                        ],
                        resources: [1000, 100 , 100 , 300],
                        img: `MANAGEMENT.jpg`
                      
                    }} imgurl='management.jpg' title="DVL Management" content="DVL Management has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners." /></div>
                  <div className="product-item"><Mycard  state= {{
                        titleState:'law',
                        about: {law},
                        indexing: [
                          'Banking Law',
                          'Company Law',
                          'Constitutional Law',
                          'Cyber Law',
                          'Family Law',
                          'International Law',
                          'IPC',
                          'Jurisprudence',
                          'Labour & Industrial Law',
                          'Professional Ethics',
                          'Property Law',
                          'Right To Information Law'
                        ],
                        resources: [200    ,   70   ,  100   ,     300],
                        img: `Law.jpg`
                      
                    }} imgurl='law.jpg' title="DVL Law" content="DVL Law provide a wealth of information regarding law and law-related issues, as well as in-depth analyses of important legal decisions. Over 3000+ records are currently available covering all legal subject" /></div>
                 
                 
                  <div className="product-item"><Mycard state= {{   titleState:'architecture', about:{architecture},
        indexing:[
          'Architectural Design',
          'Art And Design',
          'Building Construction',
          'Built Environment And Special Culture',
          'Climatology',
          'Communications And HVAC',
          'Computer And Technology',
          'Drawing Communication',
          'Energy Systems And Fire Safety',
          'History Of Architecture',
          'Lighting and Acoustics',
          'Mathematics and English',
          'Professional Practice',
          'Quantities, Specifications, Estimations, and Contract Management',
          'Surveying and Leveling',
          'Theory of Architecture',
          'Theory of Structures',
          'Town Planning',
          'Urban Issues',
          'Water Supply and Waste Disposal'
        ],
        resources:[200    ,   300  ,  120   ,  350],
        img:'ARCHITECTURE.jpg'  // Pass your data as state
      }} imgurl='architecture.jpg' title="DVL Architecture" content="DVL Architecture has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners." /></div>
                  <div className="product-item"><Mycard  state={{ titleState:'agriculture', about:{agriculture},
        indexing:[
          'Agronomy',
          'Horticulture',
          'Plant Pathology',
          'Entomology',
          'Agricultural Economics',
          'Extension education',
          'Genetics and Plant Breeding',
          'Soil Science',
          'Food technology',
          'Animal Husbandry'
        ],
        resources:[500,160,100,200],
        img:'AGRICULTURE.jpg'  // Pass your data as state
      }} imgurl='agriculture.jpg' title="DVL Agriculture" content="DVL Agriculture has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of the trust content from our publishing partners. " /></div>
                  <div className="product-item"><Mycard state= {{
          titleState:'education', 
          about: {education},
          indexing:[
            "Economics Education",
            "Education: Arts, Culture And Humanities",
            "General Education",
            "Guidance & Counselling",
            "Holistic Education",
            "Human Psychology And Behaviour",
            "Info. & Comm. Tech. For Education - ICTE",
            "Philosophy Of Education"
          ],
          resources: [700    ,   50   ,  50   ,     500],
          img: 'Education.jpg'
        
      }} imgurl='education.jpg' title="DVL Education" content="DVL Education has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners." /></div>
                  <div className="product-item"><Mycard state= {{
          titleState:'Humanity-&-Social-Science',
          about: {Humanity},
          indexing:[
            'Archaeology',
            'Art & Humanity',
            'Development Studies',
            'Disaster Management',
            'Ecology, Environment And Sustainable Development',
            'Gender Studies',
            'Gerontology',
            'International Relations',
            'Labour Studies And Social Protection',
            'Literary And Social Criticism',
            'Livelihood & Development Studies',
            'Peace and Conflict Studies',
            'Planning and Public Administration',
            'Political Science',
            'Psychiatry and Psychology',
            'Public Policy & Governance',
            'Religion and Theology',
            'Rights and Institutional Governance',
            'Sociology and Social Anthropology',
            'Urban studies'
          ],
          resources: [550 , 150   , 50  ,    500],
          img: 'HUMANITYANDSOCIALSCIENCE.jpg'
        }} imgurl='Humanity.jpg' title="DVL Humanity & Social Science" content="Humanities & Social Science is one of the major academic disciplines. The field of the study teacher’s student how history, art, music religious and culture have shaped the modern world." /></div>

                 
                  <div className="product-item"><Mycard state={{
          titleState:'Mass-Communication-&-Media',
          about: {Mass},
          indexing: [
            'Advertising And Public Relations',
            'Basic Computer Skills',
            'Digital Media And IT',
            'Documentary Project',
            'Event Management',
            'Film Communication',
            'Hindi Journalism',
            'Intercultural Communication',
            'International Relations',
            'Media Law And Ethics',
            'Media Management',
            'Photo Journalism',
            'Radio Journalism',
            'Reporting And Editing',
            'Soft Skills & Development Communication',
            'Sound Recording & Radio Production',
            'TV Journalism & Communication',
            'Design And Graphics',
            'Documentary Project',
            'English Journalism',
            'Environmental Studies'
          ],
          resources: [150,  300 ,30, 250],
          img: 'Mass.jpg'
        
      }} imgurl='Mass.jpg' title="DVL Mass Comm & Media" content="DVL Mass Com. & Media offer quick and easy access to an impressive aggregation/compilation of trust content from our publishing partners ncluding e-journal & other e-resources" /></div>
                 
                  <div className="product-item"><Mycard   state= {{
          titleState:'Basic-&-Applied-Science',
          about: {Basic},
          indexing:[
            'Zoology',
            'Statistics',
            'Science',
            'Polymer Science',
            'Physics',
            'Physical Science',
            'Microbiology',
            'Medicine And Allied Sciences',
            'Medical Lab Technology',
            'Mathematics',
            'Materials Science',
            'Geology',
            'Food Technology',
            'Computer Science',
            'Chemistry',
            'Chemical Engineering',
            'Botany',
            'Biotechnology',
            'Zoology',
            'Pathology',
            'Electrical & Electronics',
            'Biomedical Science',
            'Biology'
          ],
          resources: [690     ,   90  ,  100   ,     400],
          img: `BASICAND.jpg`
        
      }} imgurl='Basic.jpg' title="DVL Basic & Allied Science" content="DVL BASIC & APPLIED SCIENCE has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive  content." /></div>
               
                  <div className="product-item"><Mycard  state={{
                        titleState:'Forensic-&-Legal-Studies',
                        about: {Forensic},
                        indexing:  [
                          'Brain Mapping',
                          'Crime Scene',
                          'Criminology',
                          'Cyber Crime & Law',
                          'DNA Fingerprint',
                          'Explosives',
                          'Fingerprinting',
                          'Forensic Ballistics',
                          'Forensic Science',
                          'Investigation',
                          'Security Management',
                          'Toxicology',
                          'Victimology',
                          'Drugs & Narcotics'
                        ],
                        resources: [200    ,   60  ,  50  ,     250],
                        img: `FORENSICANDLEGALSTUDIES.jpg`
                      
                    }} imgurl='Forensic.jpg' title=" DVL Fore. & Legal Studies " content="DVL Forensic & Legal Studies has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive  content." /></div>

              </div>
            </div>
            <div className="pagination flex gap-2 justify-center mt-10">
    {currentPage > 1 && (
      <button className="pagination-button hover:bg-blue-600 border-opacity-20  bg-transparent  h-8 w-8 text-center text-white border-[1px] border-solid border-white rounded-11xl" onClick={() => handlePageChange(currentPage - 2)}>
        &lt; 
      </button>
    )}

    {visiblePageNumbers.map((pageNumber) => (
      <button
        key={pageNumber}
        className={`pagination-button hover:bg-blue-600 h-8 w-8   border-opacity-20  text-center bg-transparent text-white border-[1px] border-solid border-white rounded-11xl ${currentPage === pageNumber ? "active" : ""}`}
        onClick={() => handlePageChange(pageNumber)}
      >
        {pageNumber}
      </button>
    ))}

    {currentPage < totalPages && (
      <button className="pagination-button hover:bg-blue-600 bg-transparent border-opacity-20  h-8 w-8 text-center text-white border-[1px] border-solid border-white rounded-11xl" onClick={() => handlePageChange(currentPage + 2)}>
        &gt; 
      </button>
    )}
  </div>
        </div>

       

    </div>
  )
}

export default Slider


