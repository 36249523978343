export const questions = [
    {
      id: 1,
      title: `How frequently content is updated in DVL's digital library?`,
      info: `Our database is being updated regularly on weekly basis, All the updated articles/issues are getting updated on weekly basis according to the publisher's articles.`,
    },
    {
      id: 2,
      title: `How do I access DVL's digital library resources?`,
      info: `We provide DVL access through User Id & Password as well as on IP Address, Unlimited users can access all the resorces at one time.`,
    },
    {
      id: 3,
      title: `What subjects and topics are covered in DVL ?`,
      info: `We cover all the subject related to Health Sciences, Science Technology & Management,Legal Studies and Art, Social Science & Agriculture departments.
      `,
    },
    {
      id: 4,
      title: `Are there any costs associated with using DVL's services?`,
      info: `DVL access is available on annual subscription model which covers all the subject related to Medical, Paramedical and other educational departments and it comes with a different financial model for each department.`,
    },
    
  ]
  

  export const healthScienceSources = [
    "Embase",
    "Google Scholar",
    "Medline",
    "Scopus",
    "Science Citation Index Expanded (SCIE)",
    "UGC-CARE List (India)",
    "PubMed",
    "Scholarly/Peer Reviewed",
    "Web of Science",
    "DOAJ"
  ];


  export const scienceTechManagementSources = [
    "Scopus",
    "Science Citation Index Expanded",
    "CompuMath Citation Index",
    "Current Contents - Engineering, Computing & Technology",
    "INSPEC",
    "BIOSIS Citation Index",
    "Meteorological and Geoastrophysical Abstracts"
  ];

  
  export const legalStudiesSources = [
    "Australian Business Deans Council (ABDC) Journal",
    "CLOCKSS",
    "CNKI",
    "Current Contents / Social & Behavioral Sciences",
    "EBSCO Social Sciences Abstracts",
    "Google Scholar",
    "JSTOR",
    "ProQuest ABI/INFORM",
    "Scopus",
    "Social Science Citation Index",
    "UGC-CARE List (India)"
  ];

  

  export const artSocialScienceAgriSources = [
    "Journal Citation Reports",
    "Agricola",
    "BIOSIS Citation Index",
    "CAB International",
    "Elsevier Bibliographic Databases",
    "PubMed/Medline",
    "Scopus",
    "Web of Science",
    "Directory of Open Access Journals (DOAJ)"
  ];
  

export const medicalresources= [3000     ,   900   ,  250   ,     2400];
export const dentalresources=[700     ,   190    ,     300];
export const nursingresources=[440     ,   250   ,  50   ,     200];
export const pharmacyresources= [450     ,   200   ,  50   ,     200];
export const Ayushresources=[200     ,   50  ,  100   ,     550];
export const Basicresources=[690     ,   90  ,  100   ,     400];
export const homeopathyresources=[300    ,   90  ,  100   ,     400];
export const DNBresources=[300     ,   900  ,  50  ,     400];
export const physiotherapyresources=[170     ,   60  ,  80   ,     200];
export const engineeringresources=[1500  ,  2000  , 100 ,  650];
export const managementresources= [1000, 100 , 100 , 300];
export const lawresources=[200    ,   70   ,  100   ,     300];
export const Forensicresources=[200    ,   60  ,  50  ,     250];
export const architectureresources=[200    ,   300  ,  120   ,  350];
export const Humanityresources=[550 , 150   , 50  ,    500];
export const Alliedresources= [500     ,   160   ,  30   ,     100];
export const agricultureresources=[500,160,100,200];
export const Massresources=[150,  300 ,30, 250];
export const educationresources=[700    ,   50   ,  50   ,     500];


  export const Healthimg=['medical.jpg','dental.jpg','nursing.jpg','Allied.jpg','pharmacy.jpg','Ayush.jpg','Basic.jpg','homeopathy.jpg','DNB.jpg','physiotherapy.jpg'];

  export const SCIENCETECHNOLOGY=['engineering.jpg','management.jpg'];

  export const LegalStudeies=['law.jpg','Forensic.jpg'];

  export const Art=['architecture.jpg','education.jpg','Humanity.jpg','agriculture.jpg','Mass.jpg'];


   const Mass = "Media industry relevant exposure is equally necessary for preparing students to be excellent professionals in print and electronic journalism, cinema, radio, television, computer-centric multimedia & digital fields because the best kind of learning is that is beyond those four walls. On this account DVL Mass Com. & Media offer quick and easy access to an impressive aggregation/ compilation of trust content from our publishing partners including e-journals, archives, e-books, e-magazines, e-newsletters, videos, case reports, and other e-resources. DVL Mass Com. & Media helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' web platforms. Over 1000+ records are currently available covering all Mass Com. & Media subjects, together with an expanding range of specialist tools and services, for example, subject-specific search and data mining.";


   const Humanity="Humanities & Social Science is one of the major academic disciplines. The field of the study teacher’s student how history, art, music religious and culture have shaped the modern world. While on one hand, Humanities centres on art, music and literature,on the other hand,social science focuses on anthropology, archaeology and politics.DVL Humanity & Social Science covering important humanities and social sciences journal published in this field and offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archives, e-books-magazine e-newsletter, videos, case reports and other e-resources. DVL Helps users to discovers licensed content /paid resources as well as open access information. DVL provides a link to the content re-sides on the publishers’ Web platforms. Over 2000+ records are currently available covering all humanity and social science subject, together with an expanding range of specialist tools and services-for example subject-subject searched and data mining.";


   const education="DVL Education has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' Web platforms. Over 1000+ records are currently available covering Records for journal/ magazine articles, web sites, unpublished research, dissertations, books, proceedings, and audio-visual material. International and interdisciplinary coverage.";

   const agriculture="DVL Agriculture has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' Web platforms. Over 2,000+ records are currently available covering Agronomy, Horticulture, Plant Pathology, and Entomology and other related subjects & topics.";

   const architecture="DVL Architecture has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of the trust content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos case reports and other e-resources. DVL helps users to discover licenced content/paid resources as well as open, access information. DVL provides link to the content that reside on the publishers’ Web platforms. Over 1000+ records are currently available covering Architecture subject, together with an expanding range of specialist tools and service – for example subject-specific searches and data mining.";

   const Forensic="DVL Forensic & Legal Studies has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of the trust content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos case reports and other e-resources. DVL helps users to discover licenced content/paid resources as well as open, access information. DVL provides link to the content that reside on the publishers’ Web platforms. Over 1000+ records are currently available covering all forensic & legal studies subject, together with an expanding range of specialist toolsand service – for example subject-specific searches and data mining. It also gives an over view of sociological, criminological, psychological aspect of human behaviour and the method used by enforcement agencies towards ensuring public safety, internal security, criminal investigation, police administration, prosecution management, judicial management, as well as prisons & correctional services.";

   const law=`DVL Law provide a wealth of information regarding law and law-related issues, as well as in-depth analyses of important legal decisions. Unlike other online database, DVL Law provide quick and easy access to an impressive aggregation/compilation of trusted content/paid resources well as open access information. DVL pro-videos a link to the content that reside on the publishers’ Web platform. Over 3000+ records are currently available covering all legal subject, together with an expanding range of specialist tools and service for example subject-specific searches. DVL Law Journal Library bridge a crucial research gap.`;

   const management=`DVL Management has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL Management helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publisher's Web platforms. Over 3000+ records are currently available covering all management subject, together with an expanding range of specialist tools and services for example subject-specific search and data mining.`;

   const engineering=`DVL Engineering & Technology has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' Web platforms. Over 3000+ records are currently available covering all engineering subjects, together with an expanding range of specialist tools and services - for example subject-specific searches and data mining.`;
  

   const physiotherapy=`DVL Physiotherapy has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' Web platforms. Over 1000+ records are currently available covering sport, physical fitness, and physical education. Indexes the practical and research literature of sports medicine, exercise physiology, sports psychology, training, nutrition, coaching, sociology of sports, and related topics, Records for journal/ magazine articles, web sites, unpublished research, dissertations, books, proceedings, and audio-visual material. International and interdisciplinary coverage.`;

   const DNB =`DVL DNB has been specially designed to meet the need of research community worldwide. As per the guidelines of national board of examination Accredited hospital must have a good Library with adequate number of standard text books of latest edition, all the Indian journal in the specialty in which the accreditation is sought / subscribed for and preferably Two Foreign journals. DVL Fulfil all the guidelines and standards fixed by NBS for e-library. It offers quick and easy access to an impressive aggregation/ compilation of trusted content from our publishing partners including e-journal along with archive, e-books, e-magazine, e-newsletters, videos, case reports and other e-resources. DVL helps users to discover licenced content/paid resources as well as open access information. DVL DNB titles list include Indian and foreign journals recommended by National Board of Examination for all subjects.`;


   const Basic=`DVL Basic & Applied Science has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of the trust content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos case reports and other e-resources. DVL helps users to discover licenced content/paid resources as well as open, access information. DVL provides link to the content that reside on the publishers’ Web platforms. Over 1000+ records are currently available covering all Basic & Applied Science subject, together with an expanding range of specialist tools and service – for example subject-specific searches and data mining. Science can be "basic" or "applied." The goal of basic science is to understand how things work—whether it is a single cell, an organism made of trillions of cell, or a whole ecosystem. Basic science is the source of most scientific theories. For example, a scientist that tries to figure out how the body makes cholesterol, or what causes a particular disease, is performing basic science.Applied science is using scientific discoveries, such as those from basic research, to solve practical problems. For example, medicine, and all that is known about how to treat patients, is applied science based on basic research.`;


   const Ayush=`DVL Ayush & Yoga is a platform which provides all the information required for research in Ayush and Yoga. This database provides the access to 180+ full text journals and 50+ e-books covering all ayush & yoga subjects. It connects the Kayachikitsa (General Medicine Agada Tantra and Vyavaharayurveda (Toxicology, Jurisprudence and Forensic Medicine), Kayachikitsa (General Medicine),RachanaSharir (Anatomy), the database enables readers to keep abreast of current advances in their discipline and assists them in expanding their professional skills and knowledge.`;


   const  Allied=`Allied Health and Paramedics Database, your comprehensive resource for all things related to healthcare professions and emergency medical services. Our database is designed to empower students, practitioners, and researchers in the allied health and paramedics fields with a wealth of information and resources. DVL Allied Health and Paramedics offers quick and easy access to an impressive aggregation of trusted content from publishers including e-journals along with archive, e-books, e-magazine, e- newsletter, e-newspapers, videos, case reports, journals also indexed in the most recognised reference databases (PubMed, Scopus and ISI Master databases).DVL Allied Health and Paramedics having 1000+ records at present and updating regularly. DVL Allied Health and Paramedics covers all below subjects and provides full text and reliable content for research purpose.`;

   const pharmacy=`DVL Pharmacy is a platform which provides all the information required for research in Pharmacy. This database provides the access to 500+ full text journals and 200+ e-books covering all pharmacy subjects. It connects the hospital pharmacists, primary care pharmacists and leading-edge community practitioners, the database enables readers to keep abreast of current advances in their discipline and assists them in expanding their professional skills and knowledge.
  `;

   const nursing='DVL Nursing has been specially designed to meet the needs of research community worldwide. It offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL Nursing helps users to discover licensed content/paid resources as well as open access information. DVL Nursing covers all subjects related to its filed. It Provides content beyond journal, which makes its a complete online library which caters all requirements of students, facilities, researchers, and nursing care professionals.';


   const dental=`DVL Dental has been specially designed to meet the needs of dental research community worldwide. It offers quick and easy access to an impressive aggregation of trusted content from publisher including core e-journals along with archieve, e-books, e-magazine, e-newsletter, e-newspapers, videos, case reports. DVL helps users to discover all types of e-resources including open access information on a single platform.`;


   const medical=`DVL Medical is an indexing and abstracting database of health sciences. It has been specially designed to meet the needs of research community worldwide in the medical field. DVL Medical offers quick and easy access to an impressive aggregation of trusted content from publishers including e-journals along with archive, e-books, e-magazine, e- newsletter, e-newspapers, videos, case reports, journals also indexed in the most recognised reference databases (PubMed, Scopus and ISI Master databases).DVL Medical having 5000+ records at present and updating regularly. DVL Medical covers all below subjects and provides full text and reliable content for research purpose.`;


   const homeopathy=`DVL Homeopathy covering important journal published in this filed and offers quick and easy access to an impressive aggregation/compilation of trusted content from our publishing partners including e-journals along with archive, e-books, e-magazine, e-newsletter, videos, case reports and other e-resources. DVL Homeopathy helps users to discover licensed content/paid resources as well as open access information. DVL provides a link to the content that resides on the publishers' Web platforms. Over 500+ records are currently available covering all Homeopathy subject, together with an expanding range of specialist tools and services for example subject-specific search and data mining.`;


   export const abouts = {
    Mass:Mass,
    Humanity:Humanity,
    education:education,
    agriculture:agriculture,
    architecture:architecture,
    Forensic:Forensic,
    law:law,
    management:management,
    engineering:engineering,
    physiotherapy:physiotherapy,
    DNB:DNB,
    Basic:Basic,
    Ayush:Ayush,
    Allied:Allied,
    pharmacy:pharmacy,
    nursing:nursing,
    dental:dental,
    medical:medical,
    homeopathy:homeopathy
  };
  
  export const resources = {
    Mass: Massresources,
    Humanity: Humanityresources,
    education: educationresources,
    agriculture: agricultureresources,
    architecture: architectureresources,
    Forensic: Forensicresources,
    law: lawresources,
    management: managementresources,
    engineering: engineeringresources,
    physiotherapy: physiotherapyresources,
    DNB: DNBresources,
    Basic: Basicresources,
    Ayush: Ayushresources,
    Allied: Alliedresources,
    pharmacy: pharmacyresources,
    nursing: nursingresources,
    dental: dentalresources,
    medical: medicalresources,
    homeopathy: homeopathyresources
  };
  



export const medicalidx=[
  'Anatomy',
  'Biochemistry',
  'Community Medicine',
  'E.N.T. (Ear, Nose, Throat)',
  'Medicine',
  'Obstetrics & Gynecology',
  'Ophthalmology',
  'Pathology',
  'Pharmacology',
  'Physiology',
  'Radiology',
  'Anesthesia',
  'Cardiology',
  'Dermatology',
  'Forensic Medicine',
  'Microbiology',
  'Oncology',
  'Orthopedics',
  'Pediatrics',
  'Phonosurgery',
  'Psychiatric',
  'Surgery'
];
export const dentalidx=[
  'Conservative & Endodontics',
  'Orthodontics & Dentofacial Orthopedics',
  'Prosthodontics',
  'Periodontology',
  'Oral Medicine & Radiology',
  'Oral Pathology',
  'Pedodontics',
  'Oral & Maxillofacial Surgery',
  'Public Health Dentistry',
  'Dentistry & Oral Science',
  'Basic Dental Science'
];
export const nursingidx=[
  'Community Health Nursing',
  'Gerontology And Geriatric Nursing',
  'Intensive Care Nursing',
  'Medical Science Nursing',
  'Medical Surgical Nursing',
  'Mental Health & Psychiatric Nursing',
  'Midwifery & Gynaecology',
  'Oncology-Palliative Care Nursing',
  'Paediatric Nursing',
  'Physical Medicine And Rehabilitation',
  'Public Health, Nutrition And Dietetics',
  'Radiology And Nuclear Medicine'
];
export const pharmacyidx=  [
  'Pharmaceutics',
  'Pharmacology & Toxicity',
  'Pharmacognosy & Methodology',
  'Pharmaceutical Chemistry',
  'Clinical Pharmacy',
  'Drug Discovery & Analysis',
  'Pharmaceutical Dosage Forms',
  'Hospital & Community Pharmacy',
  'Industrial Pharmacy',
  'IPR & Guidelines'
];
export const Ayushidx= [
  'Agada Tantra And Vyavaharayurveda (Toxicology, Jurisprudence And Forensic Medicine)',
  'Dravyaguna (Ayurvedic Pharmacology)',
  'Kaumarabhritya (Paediatrics)',
  'Kayachikitsa (General Medicine)',
  'Kriya Sharir (Physiology)',
  'Maulika Siddhanta (Basic Principles Of Ayurveda)',
  'Naturopathy And Yoga',
  'Panchakarma',
  'Rachana Sharir (Anatomy)',
  'Rasashastra And Bhaishajya Kalpana (Medicinal Chemistry And Pharmacy)',
  'Roga Nidan & Vikriti Vigyan (Pathology)',
  'Shalya Tantra (Surgery)',
  'Stri Roga And Prasuti Tantra (Gynaecology, Obstetrics )',
  'Swasthyavritta (Social And Preventative Medicine)'
];
export const Basicidx=[
  'Zoology',
  'Statistics',
  'Science',
  'Polymer Science',
  'Physics',
  'Physical Science',
  'Microbiology',
  'Medicine And Allied Sciences',
  'Medical Lab Technology',
  'Mathematics',
  'Materials Science',
  'Geology',
  'Food Technology',
  'Computer Science',
  'Chemistry',
  'Chemical Engineering',
  'Botany',
  'Biotechnology',
  'Zoology',
  'Pathology',
  'Electrical & Electronics',
  'Biomedical Science',
  'Biology'
];
export const homeopathyidx=[
  'Homoeopathic Pharmacy',
  'Materia Medica',
  'Bacteriology & Parasitology',
  'Anatomy',
  'Community Medicine',
  'Pathology',
  'Obstetrics & Gynaecology',
  'Physiology & Biochemistry',
  'Pediatrics Pathology',
  'Forensic Medicine & Toxicology',
  'Homoeopathic Philosophy & Psychology'
];
export const DNBidx=[
  'Medicine',
  'Agricultural And Biological Sciences',
  'Anatomy',
  'Anesthesia',
  'Biochemistry',
  'Cardiology',
  'Community Medicine',
  'Dermatology',
  'E.N.T. (Ear, Nose, Throat)',
  'Forensic Medicine',
  'Medical',
  'Microbiology',
  'Obstetrics & Gynaecology',
  'Oncology',
  'Ophthalmology',
  'Orthopedics',
  'Pathology',
  'Pediatrics',
  'Pharmacology',
  'Physiology',
  'Psychiatric',
  'Radiology',
  'Surgery',
  'Tb Chest'
];
export const physiotherapyidx= [
  'Basic Medical Science',
  'Cardio & Respiratory Disorders',
  'Exercise Therapy',
  'General Medicine And Surgery',
  'Musculoskeletal And Sports Physiotherapy',
  'Orthopaedics',
  'Pediatric And Adult Neuro-Rehabilitation',
  'Physiotherapy & Occupational Therapy',
  'Rehabilitation Science',
  'Sports Science And Medicine',
  'Vestibular Rehabilitation'
];
export const engineeringidx=[
  'Biomedical Engineering',
  'Chemical Technology',
  'Civil Engineering',
  'Computer Science',
  'Electrical Engineering',
  'Electronics Engineering',
  'Environmental Engineering',
  'General Engineering',
  'Hydraulic Engineering',
  'Materials Science',
  'Mechanical Engineering',
  'Nuclear Engineering',
  'Production Engineering',
  'Robotics',
  'Tele-communication'
];
export const managementidx= [
  'Banking',
  'Computational Linguistics',
  'Computational Optimization',
  'Computational Simulation And Modeling',
  'E-Commerce',
  'Economics And Business Management',
  'Environment Management',
  'Finance And Accounting',
  'General Management',
  'Hospitality & Tourism Management',
  'Industry And Industrial Studies',
  'Information Technology',
  'Marketing Management',
  'Social Studies'
];
export const lawidx=[
  'Banking Law',
  'Company Law',
  'Constitutional Law',
  'Cyber Law',
  'Family Law',
  'International Law',
  'IPC',
  'Jurisprudence',
  'Labour & Industrial Law',
  'Professional Ethics',
  'Property Law',
  'Right To Information Law'
];
export const Forensicidx= [
  'Brain Mapping',
  'Crime Scene',
  'Criminology',
  'Cyber Crime & Law',
  'DNA Fingerprint',
  'Explosives',
  'Fingerprinting',
  'Forensic Ballistics',
  'Forensic Science',
  'Investigation',
  'Security Management',
  'Toxicology',
  'Victimology',
  'Drugs & Narcotics'
];
export const architectureidx=[
  'Architectural Design',
  'Art And Design',
  'Building Construction',
  'Built Environment And Special Culture',
  'Climatology',
  'Communications And HVAC',
  'Computer And Technology',
  'Drawing Communication',
  'Energy Systems And Fire Safety',
  'History Of Architecture',
  'Lighting and Acoustics',
  'Mathematics and English',
  'Professional Practice',
  'Quantities, Specifications, Estimations, and Contract Management',
  'Surveying and Leveling',
  'Theory of Architecture',
  'Theory of Structures',
  'Town Planning',
  'Urban Issues',
  'Water Supply and Waste Disposal'
];

export const Humanityidx=[
  'Archaeology',
  'Art & Humanity',
  'Development Studies',
  'Disaster Management',
  'Ecology, Environment And Sustainable Development',
  'Gender Studies',
  'Gerontology',
  'International Relations',
  'Labour Studies And Social Protection',
  'Literary And Social Criticism',
  'Livelihood & Development Studies',
  'Peace and Conflict Studies',
  'Planning and Public Administration',
  'Political Science',
  'Psychiatry and Psychology',
  'Public Policy & Governance',
  'Religion and Theology',
  'Rights and Institutional Governance',
  'Sociology and Social Anthropology',
  'Urban studies'
];
export const agricultureidx=[
  'Agronomy',
  'Horticulture',
  'Plant Pathology',
  'Entomology',
  'Agricultural Economics',
  'Extension education',
  'Genetics and Plant Breeding',
  'Soil Science',
  'Food technology',
  'Animal Husbandry'
];
export const Massidx=[
  'Advertising And Public Relations',
  'Basic Computer Skills',
  'Digital Media And IT',
  'Documentary Project',
  'Event Management',
  'Film Communication',
  'Hindi Journalism',
  'Intercultural Communication',
  'International Relations',
  'Media Law And Ethics',
  'Media Management',
  'Photo Journalism',
  'Radio Journalism',
  'Reporting And Editing',
  'Soft Skills & Development Communication',
  'Sound Recording & Radio Production',
  'TV Journalism & Communication',
  'Design And Graphics',
  'Documentary Project',
  'English Journalism',
  'Environmental Studies'
];
export const educationidx=[
  "Economics Education",
  "Education: Arts, Culture And Humanities",
  "General Education",
  "Guidance & Counselling",
  "Holistic Education",
  "Human Psychology And Behaviour",
  "Info. & Comm. Tech. For Education - ICTE",
  "Philosophy Of Education"
];

export const Alliedidx=[
  'ANAESTHESIA',
  'BIOCHEMISTRY',
  'CARDIAC CARE',
  'DENTAL',
  'DIAGNOSTIC',
  'DMLT - MEDICAL',
  'EMERGENCY MEDICAL',
  'GERIATRICS',
  'HEALTHCARE TECHNOLOGY',
  'HEARING & SPEECH THERAPY',
  'HEMATOLOGY & BLOOD BANK',
  'MEDICAL LABORATORY',
  'MEDICAL RECORDS & HEALTH INFORMATION',
  'NEUROLOGY',
  'NURSING',
  'NUTRITION',
  'OPERATING THEATRE',
  'OPTOMETRY',
  'PEDIATRICS',
  'PHARMACY',
  'PHYSIOTHERAPIST',
  'RADIOLOGY'
];

export const Indexes = {
  Mass: Massidx,
  Humanity: Humanityidx,
  education: educationidx,
  agriculture: agricultureidx,
  architecture: architectureidx,
  Forensic: Forensicidx,
  law: lawidx,
  management: managementidx,
  engineering: engineeringidx,
  physiotherapy: physiotherapyidx,
  DNB: DNBidx,
  Basic: Basicidx,
  Ayush: Ayushidx,
  Allied: Alliedidx,
  pharmacy: pharmacyidx,
  nursing: nursingidx,
  dental: dentalidx,
  medical: medicalidx,
  homeopathy: homeopathyidx
};