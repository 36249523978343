import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import FooterCust from '../components/HomePage/FooterCust';
import { Link } from 'react-router-dom';
import {
  Education,
  AGRICULTURE,
  ARCHITECTURE,
  Forensic,
  Law,
  Management,
  Engineering,
  Physiotherapy,
  DNB,
  Basic,
  Ayush,
  Allied,
  Pharmacy,
  Nursing,
  Dental,
  Medical,
  Homeopathy,
  Masscommmedia,
  HUMANITYANDSOCIALSCIENCE
} from '../constants';
import LaptopCategories from '../components/LaptopCategories';
import MobileLogin from '../components/MobileLogin';
import LaptopLogin from '../components/LaptopLogin';

const Privacy = () => {

  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);

  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }
  const both = () => {
    handleExpanded();
    handleServices();
  }

  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }

  return (
    <>
      <div className='font-dm-sans'>
        <div className="h-[3.63rem] fixed z-[999] max-mobile:h-[4rem] w-full text-white  bg-midnightblue font-dm-sans  text-[0.81rem]">
          <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
            <div className='pt-2'>
              <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
            </div>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>
              <Link to='/trail' className='no-underline text-white'><div className='hover:text-green-600  capitalize font-medium hover:cursor-pointer '>Home</div></Link>
              <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='hover:cursor-pointer flex items-center hover:text-green-600 capitalize font-medium'>
                Our Services

                {
                  expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
                }
                {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />}</div>
              <Link className='no-underline text-white' to='/#blogs'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></Link>

              <Link className='no-underline text-white' to='/#resources'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></Link>
              <Link to='/contact' className='no-underline text-white'><div className='hover:text-green-600 hover:cursor-pointer  capitalize font-medium '>Contact</div></Link>
            </div>



            <div className="flex gap-2 items-center">
              <Link to='/trail' className='no-underline text-white'>
                <button
                  className="hover:text-white hover:bg-blue-600 w-full py-2.5  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                >
                  Get Free Trail
                </button>
              </Link>
              <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
                <LaptopLogin />
              </a>
              {/* <Link to='/login' className='no-underline text-white'>
                        <LaptopLogin/>                        
                        </Link> */}
              {
                Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                  <img src="x-close.svg" alt="img" />
                </div>) :

                  (<div className="  lg:hidden block text-white">
                    <MenuIcon onClick={handleMobServices} className='' />
                  </div>)
              }

            </div>
          </div>


          <div className={`z-[999] fixed top-[5rem] font-dm-sans text-white  flex   gap-3 justify-center w-full  overflow-x-hidden md:max-h-[936px] max-h-[600px] ${services ? 'block' : 'hidden'}`}>
            <LaptopCategories handleServices={handleServices} />

          </div>







          {
            Mobservices &&
            (<MobileLogin expanded={expanded} handleExpanded={handleExpanded} handleMobServices={handleMobServices} />)

          }




        </div>

        <div className={`bg-[#F7F9FF] py-20 px-cust-10  ${services ? 'blur-text' : ''} `}>
          <div className=" text-indigo-950  text-[2.3rem] font-bold font-['DM Sans']">Privacy Policy</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">By using this website you agree to the terms of the Privacy Statement hereby described. However, you can prevent the use of your personal data and request to us not to be further contacted. If you wish to be informed about the personal data collected about you, or to have this data corrected or removed, please contact at <a className='font-bold' style={{ color: '#f68b27' }} href="mailto:info@avags.in"><strong>info@avags.in</strong></a>.
            <p>Dvirtual Library recognizes the importance of privacy to its users we keep personal information confidential. When you visit Dvirtual Library website and register with us, we store some information about you. In some cases we process this information in order to better understand our users and their needs.At Dvirtual Library, we care about the confidentiality and security of your personal information. We will use commercially reasonable efforts to keep your Personal Information secure in compliance with this Privacy Policy.</p></div>


          <div className="mt-16 text-indigo-950  text-[2rem]  font-bold font-['DM Sans']">About this privacy policy statement</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">This website may contain links to other websites that are beyond our control. This privacy policy applies solely to the information you provide while visiting this webite. Other websites to which you link may contain privacy policies that are different from our privacy policy.</div>



          <div className="mt-16 text-indigo-950  text-[2rem] font-bold font-['DM Sans']">Data Protection</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Dvirtual Library respects your privacy and secure your personal data. We do not sell, trade or rent the information we get from you on this site. Dvirtual Library takes all necessary steps to protect the data from un-authorised access.
            <p>Note: Sometimes we may be required to disclose the information to our information provider partners but it use only to provide you better services.</p></div>

          <div className="mt-16 text-indigo-950  text-[2rem] font-bold font-['DM Sans']">Registered User Data</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Through the register on Dvirtual Library website (Free trial form, Registration form) you agree to provide personal information (name and e-mail address, phone no and other required information). We keep our users data with us only and do not share with any third party or any other website. We may use this data to send e-mail alerts about our new products or new contents added on Dvirtual Library which may be useful for users.</div>


          <div className="mt-16 text-indigo-950  text-[2rem] font-bold font-['DM Sans']">Cookies and Log Files</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">
            When you visit Dvirtual Library, your requests and actions are automatically logged on our server. Log files contain information such as your IP address, the date and time you accessed the website, the contents you requested for viewing, etc. Log files do not contain any personal identifiable information about you.
            <p>Cookies are small amounts of information sent from a web server to your browser and stored on your computer's hard drive. They contain information about the internet websites that you have visited. Most web browsers automatically accept cookies but you can usually change your browser settings to prevent this.</p>
            <p> Dvirtual Library reserves the right to change, modify, add or remove portions of privacy policy and on this website at any time and without prior notice. Please check this page periodically for changes. Most web browsers automatically accept cookies but you can usually change your browser settings to prevent this. </p> </div>

          <div className="mt-16 text-indigo-950  text-[2rem] font-bold font-['DM Sans']">Use of Personalization and Pedagogical Improvements</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Our goal is to provide current and future visitors with the best possible educational experience. To further this goal, we sometimes present different users with different versions of e-resources and software. We may publish or otherwise publicize results from this process, but, unless otherwise permitted under this Privacy Policy, those publications or public disclosures will not include Personal Information.</div>

          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Privacy Concerns</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">If you have privacy concerns, have disclosed data you would prefer to keep private, or would like to access the information we maintain about you, please contact us <a className='font-bold' style={{ color: '#f68b27' }} href="mailto:info@avags.in"><strong>info@avags.in</strong></a>.
            <br />
            <span className='font-bold text-black'>Effective Date:</span> January 1, 2016</div>


        </div>
      </div>
      <div className='overflow-hidden text-white font-dm-sans'>
        <FooterCust />
      </div>
    </>
  )
}

export default Privacy
