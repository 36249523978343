import React, { useEffect } from 'react';

const PopupMessage = ({ message, onClose,setIsFormSubmitted }) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onClose();
      setIsFormSubmitted(false);
    }, 3000); // Adjust the duration (in milliseconds) as needed

    return () => clearTimeout(timeoutId);
  }, [onClose]);

  return (
    <div className="popup-message z-50 font-dm-sans">
      <div className="popup-content">
        <p>{message}</p>
        <button className="popup-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default PopupMessage;
