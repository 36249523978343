
  import { Link } from 'react-router-dom';


const MobileCategories = ({handleMobServices}) => {
  return (
    <>
      <div className=' '>
                             <div className="mt-6  text-[0.8rem]  leading-[1.25rem]">
                             HEALTH SCIENCE
                            </div>
                            <div className='px-6  py-6 flex flex-col gap-4'>
                           
                                                 <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'medical'.replaceAll(' ', '-')}`}
                             >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS] '>DVL Medical</div></Link>
                                          
                                          <Link   className='text-white no-underline' onClick={handleMobServices} to={`/services/${'dental'.replaceAll(' ', '-')}`}
                                            >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Dental</div></Link>
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'nursing'.replaceAll(' ', '-')}`}
                             > 
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Nursing</div></Link>
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'pharmacy'.replaceAll(' ', '-')}`}
                             >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Pharmacy</div></Link>
                                          
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'Allied Health & Paramedics'.replaceAll(' ', '-')}`}
                             >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Allied Health & Paramedics</div></Link>
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'Ayush & Yoga Science'.replaceAll(' ', '-')}`}
                             >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Ayush & Yoga Science</div></Link>
                                          
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'Basic & Applied Science'.replaceAll(' ', '-')}`}
                              >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Basic & Applied Science</div></Link>
                                          
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'homeopathy'.replaceAll(' ', '-')}`}
                             >
                                          <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.78rem] font-[DM SANS]'>DVL Homeopathy</div></Link>
                                          
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'DNB'.replaceAll(' ', '-')}`}
                              >
                                          <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL DNB</div></Link>
                                          
                                          
                                          
                                          <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'physiotherapy'.replaceAll(' ', '-')}`}
                              >
                                          <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Physiotherapy</div></Link>
                            
                            
                            </div>

                          </div>
                          
                      
                                
                                  <div className="mt-4 text-[0.8rem]  leading-[1.25rem]">
                                  SCIENCE TECHNOLOGY & MANAGEMENT 
                                  </div>
                                  <div className='px-6  py-6 flex flex-col gap-4'>

                                  <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'engineering'.replaceAll(' ', '-')}`}
                     >
                    <div className=' leading-[1.25rem] font-medium text-[0.78rem] font-[DM SANS] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Engineering</div></Link>
                    <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'management'.replaceAll(' ', '-')}`}
                      >
                    <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Management</div></Link>
                  
                              



                                  </div>
                         

                                  <div className="mt-4 text-[0.8rem]  leading-[1.25rem]">
                    LEGAL STUDIES
                                  </div>
                                  <div className='px-6  py-6 flex flex-col gap-4'>
                                  <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'law'.replaceAll(' ', '-')}`}
                     >
                    <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Law</div></Link>

                    <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'Forensic & Legal Studies'.replaceAll(' ', '-')}`}
                     >
                    <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Forensic & Legal Studies</div></Link>

                              


                                    </div>


                        
                               
                                  <div className="mt-4 text-[0.8rem]  leading-[1.25rem]">
                                 
ART, SOCIAL SCIENCE & AGRICULTURE

                                  </div>
                                  <div className='px-6  py-6 flex flex-col gap-4'>
                                  <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'architecture'.replaceAll(' ', '-')}`}
       ><div className=' leading-[1.25rem] font-medium text-[0.78rem] font-[DM SANS] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Architecture </div></Link>
        
        
        <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'agriculture'.replaceAll(' ', '-')}`} 
       >
        <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Agriculture</div></Link>
                    
                    
        <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'education'.replaceAll(' ', '-')}`} 
       >          
        <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Education</div></Link>
        
        <Link className='text-white no-underline' onClick={handleMobServices} to={`/services/${'Humanity & Social Science'.replaceAll(' ', '-')}`}   >   
        <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Humanity & Social Science</div></Link>
        
        <Link className='text-white no-underline' onClick={handleMobServices}  to={`/services/${'Mass Communication & Media'.replaceAll(' ', '-')}`} 
        >  
        <div className=' leading-[1.25rem] font-medium text-[0.78rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Mass Communication & Media</div></Link>     </div>
                          

    </>
  )
}

export default MobileCategories
