import { useRef, useEffect } from 'react';

const AboutUs = () => {
  
  return (
    <div className='flex h-auto  font-dm-sans'>
      <div className="  ml-cust-67 pt-10 mr-cust-10 w-full text-[3.44rem] text-black">
        <div className="rounded-51xl box-border w-28 h-[1.63rem] flex flex-row items-center justify-center py-[1rem] px-[1.5rem] text-right text-[0.75rem] text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500">
          <div className="leading-[1.25rem] capitalize font-medium">About Us</div>
        </div>
        <div  className="pb-4 grid xl:grid-cols-2 grid-cols-1 gap-16">
          <div className="inner-grid-item  flex flex-col items-start justify-start gap-[1.25rem]">
            <div className="mt-4 w-full min-breakpoint:text-[2.3rem] min-breakpoint:font-bold  text-[3.12rem]  tracking-[-1px] capitalize font-medium font-[DM SANS]">
           
              Empowering Education
            </div>
            <div className="pt-2 font-dm-sans  text-[1rem] leading-loose font-medium text-gray-1000 inline-block w-full">
            DVL contains more than 25,000+ records from 1000+ selected publisher covering medicine, clinical medicine, preventive medicine, pediatrics, dermatology, neurology, cardiology, gastro-enterology, nephrology, anatomy, physiology, biochemistry, pathology, dentistry, endodontics, pedodontics, oral and maxillofacial surgery, periodontics, oral pathology, nursing, education, pharmacy, pharmacology, drugs and development, management, finance, accounting, human resource, and other related sub-subjects. The database is accessible on the Internet and new journals/e-books/articles/videos are added on monthly basis. Indexed resources are published in the English language only.
            </div>

            <div className="pt-14 grid  grid-cols-1 md:grid-cols-2 md:grid-rows-2 gap-x-8 gap-y-8 w-full  text-[1rem] text-darkslategray-100">
              <div className="flex gap-2 w-full">
                <div className="rounded-[50%] bg-limegreen w-[3.13rem] h-[3.13rem]">
                  <img
                    className="w-8 p-2"
                    alt="one"
                    src="/firrelearning.svg"
                  />
                </div>

                <div className="w-full leading-[1.56rem]  font-medium   break-words">
                Comprehensive Educational and Allied Sciences Database
                </div>
              </div>

              <div className="flex gap-4 w-full ">
                <div className="rounded-[50%] bg-deeppink w-[3.13rem] h-[3.13rem]">
                  <img
                    className="w-8 p-2"
                    alt="two"
                    src="/vector.svg"
                  />
                </div>
                <div className="w-full leading-[1.56rem] font-medium ">
                Collaboration with Renowned Publishers
                </div>
              </div>

              <div className="flex gap-4 w-full ">
                <div className="rounded-[50%] bg-gray-100 w-[3.13rem] h-[3.13rem]">
                  <img
                    className="w-8 p-2"
                    alt="two"
                    src="/Vector12.svg"
                  />
                </div>
                <div className="w-full leading-[1.56rem] font-medium">
                Monthly Updates with New Content
                </div>
              </div>

              <div className="flex gap-4 w-full ">
                <div className=" rounded-[50%] bg-cornflowerblue w-[3.13rem] h-[3.13rem]">
                  <img
                    className="w-8 p-2"
                    alt="two"
                    src="/firrcomputer.svg"
                  />
                </div>
                <div className= "  w-full leading-[1.56rem] font-medium ">
                  Effortless Discovery and Access
                </div>
              </div>
            </div>
          </div>

          <div className='inner-grid-item  xl:block hidden ml-20   bg-url  '> </div>
          <div className=' mb-4 max-mobile:block hidden ml-0   max-h-[500px]'>
            <img className=' w-full object-cover' src="sh.png" alt="" />
         

          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
