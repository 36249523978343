import React from 'react'
import './Mycard.css'
import { Link } from 'react-router-dom';



const Mycard = (props) => {
    const {imgurl,content,state,title}=props;
    
    const {about,indexing,resources,titleState}=state;
  
    
    return (
        <div className='py-8 cardClass '>
        

         

            <div className='  mycard max-sm:min-w-[250px] min-w-[19.2rem] relative text-white '>
                <img className="absolute  rounded-[1.06rem]  w-full h-full object-cover  " alt="" src={imgurl}/>
                <div className="absolute  h-full w-full rounded-[1.06rem] [background:linear-gradient(0deg,_rgba(0,_0,_0,_0.74),_rgba(0,_0,_0,_0.44)_77.23%,_rgba(0,_0,_0,_0))] "></div>
                    
                    <div className='  absolute mt-2-cust  px-5  flex flex-col '>
                        <b className=" text-[25px] tracking-[-1px] capitalize text-white [text-shadow:0px_9px_40px_rgba(0,_0,_0,_0.25)]">
                        {title}
                        </b>  
                        <div className="pt-4  text-[0.88rem] leading-[1.38rem] font-medium ">{content}</div>

                        <Link  className='text-white no-underline 'to={`/services/${titleState.replaceAll(' ', '-')}`} >
                            <div className="mt-5 hover:bg-blue-900 hover:text-white hover:cursor-pointer w-7/12 max-sm:w-9/12 rounded-51xl bg-aqua box-border  flex flex-row items-center justify-start py-2 px-[1.5rem] text-right text-[0.75rem] text-black border-[1px] border-solid border-mediumslateblue-600">
                                <div className="">
                                    <div className="  leading-[1.25rem] capitalize font-medium">
                                        Explore More
                                        <img className="pl-2 " alt="" src="/vector3.svg"></img>
                                    </div>
                                
                                </div>
                            </div>
                        </Link>   
                    </div>
              
            </div>

        </div>

    )
}

export default Mycard;