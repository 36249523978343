import { abouts,Indexes,resources} from '../constants';


import FooterCust from "../components/HomePage/FooterCust";
import Trail from "../components/HomePage/Trial";
import AboutUsSection from "../components/OurServices/AboutUsSection";
import Benefits from "../components/OurServices/Benefits";
import Categories from "../components/OurServices/Categories";
import Help from "../components/OurServices/Help";
import NavbarPage from "../components/OurServices/NavbarPage";
import Offerings from "../components/OurServices/Offerings";
import { useParams } from 'react-router-dom';


const OurServices = (props) => {
  
  
  const { title } = useParams();
  const firstWord = title.split('-')[0];
 
 
  
  // Splitting the title by '-' and capitalizing each word
  const formattedTitle = title
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // Add 'DVL' in front of the formatted title with a space
  const prefixedTitle = `DVL ${formattedTitle}`;

  const imgnames=`${firstWord}.jpg`;
  


  const findingAbout = abouts[firstWord];   
  const findingIndex = Indexes[firstWord];
  const findingResource = resources[firstWord]; 

  //const {about,img,indexing,resources} = location.state; // Access the entire state object
  //const { about, indexing, resources, img } = props;
 


  return (
    <div>
      
        <NavbarPage img={imgnames} title={prefixedTitle} filename={firstWord}/>
        <AboutUsSection title={prefixedTitle}   resources={findingResource} about={findingAbout}/>
        
        <Categories data={findingIndex}/>
  
        <Benefits/>
        <Offerings img={imgnames} />
        <Help/>
        <div className="text-white font-dm-sans">
          <Trail/>
        </div>
       
        <div className=" overflow-hidden  text-white font-dm-sans mt-16">
          <FooterCust/>
        </div>
 
 
    </div>
  )
}

export default OurServices
