
  import { Link } from 'react-router-dom';

const LaptopCategories = ({handleServices}) => {
  return (
    <>
        <div className='  border-2 pb-12  border-solid bg-blue-950 rounded-lg  border-blue-600 border-opacity-10'>
            <div className='md:px-10 px-4 py-6 flex flex-col gap-8'>
                    <b className=" h-[72px] pb-2 leading-[1.25rem]">
                    	HEALTH SCIENCE
                    </b>
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'medical'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS] '>DVL Medical</div></Link>
                    
                    <Link   onClick={handleServices}  className='text-white no-underline' to={`/services/${'dental'.replaceAll(' ', '-')}`}
                     >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Dental</div></Link>
                    
                    <Link  onClick={handleServices}  className='text-white no-underline' to={`/services/${'nursing'.replaceAll(' ', '-')}`}
       > 
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Nursing</div></Link>
                    
                    
                    <Link  onClick={handleServices}  className='text-white no-underline'  to={`/services/${'pharmacy'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Pharmacy</div></Link>
                    
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'Allied Health & Paramedics'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Allied Health & Paramedics</div></Link>
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'Ayush & Yoga Science'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Ayush & Yoga Science</div></Link>
                    
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline'  to={`/services/${'Basic & Applied Science'.replaceAll(' ', '-')}`}
        >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Basic & Applied Science</div></Link>
                    
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'homeopathy'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] text-slate-300 hover:text-green-600 hover:cursor-pointer font-medium text-[0.9rem] font-[DM SANS]'>DVL Homeopathy</div></Link>
                    
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'DNB'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL DNB</div></Link>
                    
                    
                    
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'physiotherapy'.replaceAll(' ', '-')}`}
       >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Physiotherapy</div></Link>
                  </div>
              </div>

              <div className='border-2  w-[300px] border-solid bg-blue-950 rounded-lg  border-blue-600 border-opacity-10'>
                  <div className='md:pl-8 xl:px-8 px-4 py-6 flex flex-col gap-8'>
                    <b className="max-h-[72px]   pb-2 leading-loose">
                    SCIENCE TECHNOLOGY & MANAGEMENT
                    </b>
                    <Link className='text-white no-underline' to={`/services/${'engineering'.replaceAll(' ', '-')}`} onClick={handleServices}
                      >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] font-[DM SANS] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Engineering</div></Link>
                    <Link className='text-white no-underline' to={`/services/${'management'.replaceAll(' ', '-')}`} onClick={handleServices}
                     >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Management</div></Link>
                          </div>
              </div>

                    
              <div className='border-2  w-[300px] border-solid bg-blue-950 rounded-lg  border-blue-600 border-opacity-10'>
                  <div className='md:px-8 px-4 py-6 flex flex-col gap-8'>
                    <b className="h-[72px]  pb-2 leading-loose">
                    	LEGAL STUDIES
                    </b>
                    <Link className='text-white no-underline' onClick={handleServices} to={`/services/${'law'.replaceAll(' ', '-')}`}
                    >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Law</div></Link>

                    <Link className='text-white no-underline' onClick={handleServices} to={`/services/${'Forensic & Legal Studies'.replaceAll(' ', '-')}`}
                     >
                    <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Forensic & Legal Studies</div></Link>
            
                             </div>
              </div>
            

              <div className='border-2 w-[316px] border-solid bg-blue-950 rounded-lg  border-blue-600 border-opacity-10'>
                  <div className='md:px-8 px-4 py-6 flex flex-col gap-8 '>
                    <b className="  pb-2 leading-loose h-[72px]">
                    ART, SOCIAL SCIENCE & AGRICULTURE
                    </b>
                    <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'architecture'.replaceAll(' ', '-')}`}
       ><div className=' leading-[1.25rem] font-medium text-[0.9rem] font-[DM SANS] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Architecture </div></Link>
        
        
        <Link className='text-white no-underline'  to={`/services/${'agriculture'.replaceAll(' ', '-')}`} onClick={handleServices}
       >
        <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Agriculture</div></Link>
                    
                    
        <Link className='text-white no-underline' to={`/services/${'education'.replaceAll(' ', '-')}`} onClick={handleServices}
       >          
        <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Education</div></Link>
        
        <Link className='text-white no-underline'  to={`/services/${'Humanity & Social Science'.replaceAll(' ', '-')}`}  onClick={handleServices}  >   
        <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Humanity & Social Science</div></Link>
        
        <Link onClick={handleServices} className='text-white no-underline' to={`/services/${'Mass Communication & Media'.replaceAll(' ', '-')}`} 
       >  
        <div className=' leading-[1.25rem] font-medium text-[0.9rem] text-slate-300 hover:text-green-600 hover:cursor-pointer'>DVL Mass Communication & Media</div></Link>
                  </div>
            </div>
    </>
  )
}

export default LaptopCategories;
