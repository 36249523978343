import React from 'react'
import Category from './Category'

const Categories = ({data}) => {
  
  return (
    <div className='font-dm-sans md:px-[1%] px-[2%]' >
        <div style={{width: '100%', height: '100%', background: 'rgba(52.06, 96.71, 255, 0.02)', borderRadius: 26, border: '1px rgba(52.06, 96.71, 255, 0.20) solid'}} >
            <div className='flex flex-col gap-2  items-center py-14'>
                <div className="bg-white flex justify-center  rounded-51xl box-border  w-[100px]     px-6 py-2  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
                    <div className=" text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Categories</div>
                </div>

              
                <div className=" text-black text-center text-[2rem] font-bold   md:text-[2.8rem]   lg:font-medium lg:text-[55px] font-['DM Sans'] capitalize">Access to {data?.length || 10}+ Subject Disciplines</div>
                <div className="text-black text-center text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Check here all the categories we offer</div>
                
                <div className='mt-6 gap-2 flex justify-center flex-wrap  w-[75%]  max-mobile:w-[80%]'>
            {data.map((category, index) => (
              <div key={index} className="category-wrapper mt-4">
                <Category name={category} />
              </div>
            ))}
          </div>
                {/*}
                <div className='gap-2 flex mt-8'>
                       <Category name="Anatomy"/>
                       <Category name="Anesthesia"/>
                       <Category name="Cardiology"/>
                       <Category name="Community Medicine"/>
                       <Category name="Biochemistry"/>
                </div>

                <div className='flex gap-2 mt-3'>
                       <Category name="E.N.T. (Ear, Nose ,Throat)"/>
                       <Category name="Dermatology"/>
                       <Category name="Forensic Medicine"/>
                       <Category name="Medicine"/>
                     
                </div>


                <div className='flex gap-2 mt-3'>
                       <Category name="Microbiology"/>
                       <Category name="Obstetrics & Gynecology"/>
                       <Category name="Oncology"/>
                       <Category name="Psychiatric"/>
                      
                </div>

                <div className=''>
                    <div className='flex gap-2 mt-3 flex-wrap'>
                        <div><Category name="Ophthalmology"/></div>
                        <div><Category name="Pathology"/></div>
                        <div><Category name="Pediatrics"/></div>
                        <div><Category name="Phonosurgery"/></div>
                        <div> <Category name="Physiology"/></div>
    
                    </div>

                </div>


                <div className='flex gap-2 mt-3'>
                       <Category name="Radiology"/>
                       <Category name="Surgery"/>
                     
                </div>
  {*/}
            </div>
        </div>
      
    </div>
  )
}
 
export default Categories
