import { useEffect, useState } from "react";

const DailyBlogs = () => {

    const [childbox,setChildBox]=useState({});
    const [box,setBox]=useState({});
  
    const getBox=()=>{
      console.log('inside getBox Testimonial');
       let box2 = document.querySelector('.blog-container');
       let box3 = document.querySelector('.blog-item');
       setBox(box2);
       setChildBox(box3);
    }
  
    useEffect(()=>{
      getBox();
  
    },[]);
  
   
    console.log('box',box);
    const btnpressprev = () => {
        let width = box.clientWidth;
        let childwidth = childbox.clientWidth;
        console.log('width',width);
        console.log('childwidth',childwidth);
        box.scrollLeft = box.scrollLeft - childwidth-32;
        console.log(width)
    }
  
    const btnpressnext = () => {
        let width = box.clientWidth;
        let childwidth = childbox.clientWidth;
        console.log('width',width);
        console.log('childwidth',childwidth);
        box.scrollLeft = box.scrollLeft + childwidth+32;
        console.log(width)
    }
  


  return (
    <div className='flex pb-14 pt-24  bg-slate-100'>
        <div className='ml-cust-67  mr-cust-10 w-full'>
            <div className='grid grid-cols-1 lg:grid-cols-3  lg:gap-20 gap-10'>
                <div className='col-span-1   text-black'> 
                    <div className='flex flex-col'>

                        <div className=" bg-slate-50 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border-[1px] box-border border-solid border-mediumslateblue-500  justify-center items-center gap-2.5 flex flex-row">
                            <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Blogs</div>
                        </div>
                        <div className=" min-breakpoint:text-[2.3rem] min-breakpoint:font-bold   pt-4 lg:text-[2.3rem] text-black xl:text-[55px] text-[55px] font-medium font-['DM Sans'] capitalize">Daily Blogs </div>
                        <div className="pt-4 pl-1  text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">
                            Daily take a look at latest trend in the field of tech that is making a hallmark landmark for the world and just check this out.
                            These blogs are designed to keep you up-to-date with the rapidly evolving fields of medicine, science, technology, and more.
                            Stay connected with DVL to explore the latest discoveries, trends, and developments in your area of interest.
                             </div>

                        <div className="   mt-8    bordcust bg-slate-50 max-w-[110px]  shadow-xl h-[5px] px-2 py-4 rounded-[70px]   justify-center items-center gap-2.5 flex flex-row">
                            <div className="flex gap-2 items-center   text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Learn More
                                <img src="vector-1.svg" alt="arrow" />
                            </div>
                        </div>
                    </div>
                </div>



                <div className='col-span-2 md:pr-16  text-black  '>
               
                    <div className=" max-mobile:mt-4 flex ">
                        <div className="ml-auto flex gap-3 mr-1 mb-10">
                                <div onClick={()=>{btnpressprev()}}  className="hover:bg-slate-400 hover:cursor-pointer flex justify-center items-center  h-16 w-16 bg-white border-[1px] border-solid border-slate-200 rounded-full " >
                                <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className="text-white text-[1rem] font-dm-sans ">
                                    <img src="bi_arrow-left-short-1.svg" alt="arrow" />
                                </span>
                                </div>
                            
                                <div onClick={()=>{btnpressnext()}}  className="hover:bg-blue-800  hover:cursor-pointer flex justify-center items-center h-16 w-16 bg-blue-900 rounded-full" >
                                    <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className=" text-white ">
                                        <img src="bi_arrow-left-short.svg" alt="arrow" />
                                    </span>
                                </div>
                        </div>
                        
                    </div>


                    <div className=' blog-container  flex  overflow-hidden gap-8'>
                        <div className='blog-item  max-w-[23rem]  max-sm:min-w-[250px] min-w-[20rem]  min-mobile:min-w-[23rem]   bg-white rounded-[17px] shadow border border-black border-opacity-10'>
                            <div className='flex flex-col'>
                                <div className='w-full h-[211px]'>
                                    <img className='h-full w-full object-cover rounded-tl-[17px] rounded-tr-[17px]' src="/rectangle-517@2x.png" alt="2" />
                                </div>
                                <div className='pt-6  flex flex-col px-6'>
                                    <div className="bg-slate-100 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border border-blue-600 border-opacity-10 justify-center items-center gap-2.5 flex flex-row">
                                        <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Buisness</div>
                                    </div>
                                    <div className="pt-4 text-black text-5xl font-bold font-['DM Sans'] capitalize">Creating Intuitive Products that benefit people</div>
                                    <div className="pt-4">
                                        <span className=" text-black text-opacity-60 text-sm font-normal font-['DM Sans'] leading-[25px]">In 2016, A Medium Corporation acquired the rich media embedding platform Embedly, a provider of content integration services for numerous websites, including Medium itself... </span><span className="text-blue-600 text-sm font-normal font-['DM Sans'] leading-[25px]">Read more</span>
                                    </div>
                                    <div className="pt-6 mb-4 ml-auto text-black text-opacity-60 text-sm font-medium font-['DM Sans'] leading-[25px]">25 January, 2023</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='blog-item  max-w-[23rem]  max-sm:min-w-[250px]   min-w-[20rem]  min-mobile:min-w-[23rem]    bg-white rounded-[17px] shadow border  border-black border-opacity-10'>
                            
                            <div className='flex flex-col'>
                                <div className='w-full h-[211px]'>
                                    <img className='h-full w-full object-cover rounded-tl-[17px] rounded-tr-[17px]' src="/rectangle-5171@2x.png" alt="2" />
                                </div>
                                <div className='pt-6 flex flex-col px-6'>
                                    <div className="bg-slate-100 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border border-blue-600 border-opacity-10 justify-center items-center gap-2.5 flex flex-row">
                                        <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Buisness</div>
                                    </div>
                                    <div className="pt-4 text-black text-5xl font-bold font-['DM Sans'] capitalize">Creating Intuitive Products that benefit people</div>
                                    <div className="pt-4">
                                        <span className=" text-black text-opacity-60 text-sm font-normal font-['DM Sans'] leading-[25px]">In 2016, A Medium Corporation acquired the rich media embedding platform Embedly, a provider of content integration services for numerous websites, including Medium itself... </span><span className="text-blue-600 text-sm font-normal font-['DM Sans'] leading-[25px]">Read more</span>
                                    </div>
                                    <div className="pt-6  mb-4 ml-auto text-black text-opacity-60 text-sm font-medium font-['DM Sans'] leading-[25px]">25 January, 2023</div>
                                </div>
                            </div>
                        </div>

                           
                        <div className='blog-item max-w-[23rem]  max-sm:min-w-[250px]    min-w-[20rem]  min-mobile:min-w-[23rem]    bg-white rounded-[17px] shadow border  border-black border-opacity-10'>
                            
                            <div className='flex flex-col'>
                                <div className='w-full h-[211px]'>
                                    <img className='h-full w-full object-cover rounded-tl-[17px] rounded-tr-[17px]' src="/rectangle-5171@2x.png" alt="2" />
                                </div>
                                <div className='pt-6 flex flex-col px-6'>
                                    <div className="bg-slate-100 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border border-blue-600 border-opacity-10 justify-center items-center gap-2.5 flex flex-row">
                                        <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Buisness</div>
                                    </div>
                                    <div className="pt-4 text-black text-5xl font-bold font-['DM Sans'] capitalize">Creating Intuitive Products that benefit people</div>
                                    <div className="pt-4">
                                        <span className=" text-black text-opacity-60 text-sm font-normal font-['DM Sans'] leading-[25px]">In 2016, A Medium Corporation acquired the rich media embedding platform Embedly, a provider of content integration services for numerous websites, including Medium itself... </span><span className="text-blue-600 text-sm font-normal font-['DM Sans'] leading-[25px]">Read more</span>
                                    </div>
                                    <div className="pt-6 mb-4 ml-auto text-black text-opacity-60 text-sm font-medium font-['DM Sans'] leading-[25px]">25 January, 2023</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
           
        </div>
      
    </div>
  )
}

export default DailyBlogs
