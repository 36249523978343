import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import FooterCust from '../components/HomePage/FooterCust';
import { Link } from 'react-router-dom';
import {
  Education,
  AGRICULTURE,
  ARCHITECTURE,
  Forensic,
  Law,
  Management,
  Engineering,
  Physiotherapy,
  DNB,
  Basic,
  Ayush,
  Allied,
  Pharmacy,
  Nursing,
  Dental,
  Medical,
  Homeopathy,
  Masscommmedia,
  HUMANITYANDSOCIALSCIENCE
} from '../constants';
import LaptopCategories from '../components/LaptopCategories';
import MobileLogin from '../components/MobileLogin';
import LaptopLogin from '../components/LaptopLogin';

const Terms = () => {


  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);

  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }
  const both = () => {
    handleExpanded();
    handleServices();
  }
  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }

  return (
    <>
      <div className='font-dm-sans'>
        <div className=" w-full text-white fixed z-[999]  bg-midnightblue font-dm-sans max-mobile:h-[4rem] h-[3.63rem] text-[0.81rem]">
          <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
            <div className='pt-2'>
              <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
            </div>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>
              <Link to='/trail' className='no-underline text-white'><div className='hover:text-green-600  capitalize font-medium hover:cursor-pointer '>Home</div></Link>
              <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='flex items-center hover:text-green-600  hover:cursor-pointer capitalize font-medium'>
                Our Services

                {
                  expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
                }
                {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />}
              </div>
              <Link className='no-underline text-white' to='/#blogs'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></Link>

              <Link className='no-underline text-white' to='/#resources'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></Link>
              <Link to='/contact' className='no-underline text-white'><div className='hover:text-green-600 hover:cursor-pointer  capitalize font-medium '>Contact</div></Link>

            </div>



            <div className="flex gap-2 items-center">
              <Link to='/trail' className='no-underline text-white'>
                <button
                  className="hover:bg-blue-600 hover:text-white  w-full py-2.5  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                >
                  Get Free Trial
                </button>
              </Link>
              <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
                <LaptopLogin />
              </a>
              {/* <Link to='/login' className='no-underline text-white'>
                        <LaptopLogin/>                        
                        </Link> */}
              {
                Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                  <img src="x-close.svg" alt="img" />
                </div>) :

                  (<div className="  lg:hidden block text-white">
                    <MenuIcon onClick={handleMobServices} className='' />
                  </div>)
              }

            </div>
          </div>


          <div className={`fixed z-[999] top-[5rem] font-dm-sans text-white  flex   gap-3 justify-center w-full  overflow-x-hidden md:max-h-[936px] max-h-[600px] ${services ? 'block' : 'hidden'}`}>
            <LaptopCategories handleServices={handleServices} />

          </div>







          {
            Mobservices &&
            (<MobileLogin expanded={expanded} handleExpanded={handleExpanded} handleMobServices={handleMobServices} />)

          }


        </div>




        <div className={`bg-[#F7F9FF] py-20 px-cust-10  ${services ? 'blur-text' : ''} `}  >
          <div className=" text-indigo-950 md:text-[2.3rem] text-[3rem] font-bold font-['DM Sans']">Terms of use</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Use of this site is subject to the following Terms and Conditions. This site is operated and powered by AVAGS INFORMATION SYSTEMS registered under Indian law. The site is known as Dvirtual Library (DVL).</div>


          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Authorised Users</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Any person may access the open parts of this site in accordance with the Terms and Conditions. Dvirtual Library provides a user access to its register user to access the full content of this site who paid the annual subscription charges.</div>



          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Copying or Downloading of Individual Objects</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Users may print off or make single copies of web pages for personal use. Users may also save web pages other than individual articles electronically for personal use. Bulk copies or mailing of articles is not permitted, without prior permission from Avags Information Systems.The terms for access to individual content by users are in accordance with international & Indian copyright law.</div>

          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Permitted Use</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">You are allowed access to browse the website for academy/research use. Unreasonable use, including the systematic downloading of content without permission, will result in access to the website being blocked. Access is prohibited for any commercial use.</div>


          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Modificatios</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">Avags Information Systems reserves the right to change, modify, add or remove portions of these Terms and Conditions and on this site at any time and without prior notice. Please check this page periodically for changes.</div>


          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Warranty</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">
            Avags Information Systems provides access to all materials on a 24/7/365 basis through it's website www.dvlonline.com, but cannot be held liable for any loss of service or any legal action for any loss. Avags Information Systems take necessary steps to ensure that the site is secure and viruses free, but it is not responsible for any loss of data or hardware damage caused through site usage. Nor is the security of information passed electronically to the site guaranteed.

            <p> Avags Information Systems do not warranty the information on this site, or on any linked site. Avags Information Systems do not accept any responsibility for any loss, damage or lawsuit related to the use of information contained within website <Link to='/'>www.dvlonline.com</Link> or any linked site.
            </p>
          </div>

          <div className="mt-16 text-indigo-950 text-[2rem] font-bold font-['DM Sans']">Applicable Law</div>
          <div className="mt-4 text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">These terms and conditions will be in accordance with the Indian Law. Any dispute will be subject to the jurisdiction of Delhi (Dwarka) Only. </div>


        </div>



      </div>
      <div className='overflow-hidden text-white font-dm-sans'>
        <FooterCust />
      </div>
    </>
  )
}

export default Terms
