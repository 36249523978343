import { questions } from "../../constants";
import {useState} from 'react';

const Help = () => {

    const [expanded,setExpanded]=useState({});

    const toggle=(id)=>{
        setExpanded({...expanded,[id]:!expanded[id]})
    }


  return (
    <div className="text-black font-dm-sans  mt-20">
        <div className="lg:ml-cust-5 mr-cust-5 grid grid-cols-1 md:grid-cols-2">

            <div className="md:pl-18 ml-9 pb-20 block md:hidden">
                <div className="flex flex-col gap-5">
                    <div className="lg:text-[1.5rem] md:text-[2.6vw] text-[30px] max-sm:text-[22px] xl:text-[2.25rem]  tracking-[-1px] md:leading-[3.25rem] capitalize font-medium inline-block ">How Can We Help You?</div>
                    <div className="md:w-4/5  -mt-1 md:text-[1.3vw] lg:text-[0.8rem] xl:text-[1.25rem] xl:leading-[2.25rem] font-medium text-gray-1000 inline-block  opacity-[0.75] mix-blend-normal">Reach out to us for any query you have , our team will take care of it</div>
                    <div className="flex flex-nowrap gap-2">
                        <input 
                                className="md:pl-4 w-full bg-gray-50 placeholder-[#AAAAAA] max-w-[400px] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                                type="text"
                                placeholder="Enter Email"
                        />

                        <button 
                            className="w-full whitespace-nowrap bg-mediumslateblue-200 max-w-[114px] text-white lg:h-[40px] font-medium font-[DM Sans] leading-tight border border-mediumslateblue-500  rounded-11xl p-2"
                            >
                            Lets Talk
                        </button>

                       
                    </div>
                </div>
            </div>



            <div className='    question-container'>
                <ul className='list-none flex flex-col   gap-2 '>
                {
                    questions.map(question=>(
                        <div  key={question.id} className="py-4 border-t-[1.5px] border-solid border-[#d5d5d5] flex flex-col justify-center ">

                            {/*<img className="text-black" src="Vector6.svg" alt="" />
                        */}
                        <li className="flex justify-between items-center">


                        <div className="lg:leading-[1.6rem] lg:text-[1rem] xl:text-[1.2rem] md:text-[1.6vw] text-[20px] max-sm:text-[16px]  py-2  font-bold ">{question.title}</div>

                       
                        <div className='buttons'>
                            {
                                expanded[question.id] ? (<button className='ShowLess-btn' onClick={()=>toggle(question.id)}>-</button>) :(<img  src="materialsymbolsaddcirclerounded.svg" className='ShowLess-btn' onClick={()=>toggle(question.id)}/>)
                            }
                         </div>
                            
                        
                        </li>
                        {
                            expanded[question.id]? (<div className=' lg:text-[0.8rem]  pr-2  max-sm:text-[14px]    lg:mt-2 mt-3  xl:text-[1rem] xl:leading-[1.5rem]  md:text-[1.3vw] lg:leading-[1rem] md:leading-[1rem] font-medium text-gray-1000'>{question.info}</div> ) : (null)
                        }
                        </div>
                    
                    
                    ))
                }
                </ul>
            </div>

            <div className="md:pl-20 lg:ml-9  hidden md:block">
                <div className="flex flex-col gap-5">
                    <div className="lg:text-[1.5rem] md:text-[2.6vw] text-[30px] max-sm:text-[22px] xl:text-[2.25rem]  tracking-[-1px] md:leading-[3.25rem] capitalize font-medium inline-block ">How Can We Help You?</div>
                    <div className="md:w-4/5 max-sm:text-[12px] leading-5 -mt-1 md:text-[1.3vw] lg:text-[0.8rem] xl:text-[1.25rem] xl:leading-[2.25rem] font-medium text-gray-1000 inline-block  opacity-[0.75] mix-blend-normal">Reach out to us for any query you have , our team will take care of it</div>
                    <div className="flex flex-nowrap gap-2">
                        <input 
                                className="md:pl-4 w-full bg-gray-50 placeholder-[#AAAAAA] max-w-[400px] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                                type="text"
                                placeholder="Enter Email"
                        />

                        <button 
                            className="w-full whitespace-nowrap bg-mediumslateblue-200 max-w-[114px] text-white lg:h-[40px] font-medium font-[DM Sans] leading-tight border border-mediumslateblue-500  rounded-11xl p-2"
                            >
                            Lets Talk
                        </button>

                       
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Help
