import { Link } from "react-router-dom"

const Trail = () => {
    return (
      <div className="mb-20 w-full overflow-hidden">
          <div className="ml-cust-10  pt-10 mr-cust-10 ">
              <div className="flex  flex-row justify-center rounded-7xl    ">
                  
                  <div className="  flex md:justify-center w-full ">
                      {/*}
                      <div className="border-2 border-solid border-midnightblue  gradient2 h-full md:w-96 rounded-l-full" style={{ borderRadius: '100%' }}></div>
                      <div className="border-2 border-solid border-sky-500  gradient1 h-full md:w-96 rounded-r-full" style={{ borderRadius: '100%' }}></div>
                      {*/}
                      <div className=" trailbgimg min-h-[384px] w-full ">
                        <div className="   ">
                            <div className="flex flex-col pt-16">
                                <div className="max-mobile:text-[28px] text-center text-white text-[40px] font-medium font-['DM Sans'] capitalize">Get started With A<br/> free trial</div>
                                <div className="max-mobile:text-[13px] max-mobile:px-4 px-4 md:px-0 pt-4 text-center text-white text-base font-medium font-['DM Sans'] leading-loose">Start your journey of getting study resource at your fingertips in digital system</div>
                                <div className="flex justify-center pb-4">
                                    
                                    <Link to='/trail' className="mt-7 no-underline  rounded-[70px] text-white">
                                        <div className=" hover:cursor-pointer  bg-white h-[18px] px-8 py-4 rounded-[70px]  border border-blue-600 border-opacity-10 justify-center items-center gap-2.5 flex flex-row">
                                            <div className=" text-center leading-[1.25rem]   text-mediumslateblue-200  font-medium font-['DM Sans'] capitalize ">Book Now
                                                <img
                                                className="pl-2 w-[0.72rem] h-[0.63rem]"
                                                alt=""
                                                src="/vector2.svg"
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                      </div>
                      
                  </div>
                
              </div>
          </div>
          
          {/*}
          <div className="absolute top-[235.5rem] left-[calc(50%_-_601px)] flex flex-col items-center justify-center text-center">
              <div className="relative rounded-7xl bg-mediumslateblue-100 w-[75.13rem] h-[21.63rem] overflow-hidden shrink-0">
              <img
                  className="absolute top-[0rem] left-[12.38rem] w-[49.48rem] h-[29.86rem]"
                  alt=""
                  src="/group-2234.svg"
              />
              <div className="absolute top-[11.13rem] left-[19.13rem] leading-[2rem] font-medium inline-block w-[38rem]">
                  Start your journey of getting study resource at your fingertips in
                  digital system
              </div>
              <div className="absolute top-[3.44rem] left-[17.94rem] text-[2.5rem] tracking-[-1px] capitalize font-medium inline-block w-[38.38rem]">
                  <p className="m-0">Get started With A</p>
                  <p className="m-0"> free trial</p>
              </div>
              <div className="absolute top-[14.94rem] left-[33.06rem] rounded-51xl bg-white flex flex-row items-center justify-start py-[1rem] px-[1.5rem] text-right text-mediumslateblue-200">
                  <div className="relative w-[6.03rem] h-[1.25rem]">
                  <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                      Book Now
                  </div>
                  <img
                      className="absolute top-[0.31rem] left-[5.31rem] w-[0.72rem] h-[0.63rem]"
                      alt=""
                      src="/vector2.svg"
                  />
                  </div>
              </div>
              </div>
        </div>
        {/*/}
       
      </div>
    )
  }
  
  export default Trail
  