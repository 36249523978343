import React from 'react';
import { healthScienceSources,artSocialScienceAgriSources, scienceTechManagementSources, legalStudiesSources,Healthimg,SCIENCETECHNOLOGY,LegalStudeies,Art } from '../../constants'; 

const Offerings = ({img}) => {

    let indexing = null;

    // Check if img is in one of the arrays and set indexing accordingly
    if (Healthimg.includes(img)) {
      indexing = healthScienceSources;
    } else if (SCIENCETECHNOLOGY.includes(img)) {
      indexing = scienceTechManagementSources;
    } else if (LegalStudeies.includes(img)) {
      indexing = legalStudiesSources;
    } else if (Art.includes(img)) {
      indexing = artSocialScienceAgriSources;
    }

  return (
    <div className='flex pb-14 pt-14 text-white bg-slate-50 font-dm-sans '>
        <div className='lg:ml-cust-10   ml-cust-5 mr-cust-5 md:mr-4 xl:mr-cust-5  w-full'>
            <div className="mb-6  rounded-51xl box-border  inline-block  text-center bg-white  px-6 py-2  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
                <div className="text-center  font-medium  font-['DM Sans']  leading-tight">Offerings</div>
            </div>
            <div className='grid grid-cols-1  gap-8 md:gap-16 lg:gap-2  xl:gap-16 md:grid-cols-3 lg:grid-cols-2  '>
                <div className='    text-black'> 
                    <div className='flex flex-col'>

                      
                   
                        <div className="font-dm-sans md:font-bold xl:font-medium font-medium hidden md:block  tracking-[-1px] capitalize xl:text-[3.44rem] text-[3rem] md:text-[2rem]  pt-4 text-black">Unique Offerings at  your Fingertips</div>
                        
                        {/*<div className="hidden md:block tracking-[-1px] capitalize font-medium lg:text-[3.44rem] text-[3.44rem] md:text-[2rem] text-black"> your Fingertips </div>
                            */}
                        <div className=" text-[2.44rem] max-sm:text-[2rem] md:hidden block tracking-[-1px] capitalize font-[550]    text-black">Unique Offerings at your Fingertips </div>


                        <div className="mt-6 pl-1 md:w-4/5 text-[1rem]  leading-[2rem] font-medium text-gray-900 ">
                            Dvirtual Library (DVL) is a leading digital resource hub dedicated to empowering excellence in medical education and research. 
                        </div>


                        

                       
                    </div>
                </div>
                <div className='bg-slate-50  md:col-span-2 lg:col-span-1 md:pr-4 xl:pr-16  text-black flex md:flex-row flex-col overflow-x-hidden gap-8 lg:gap-4 xl:gap-8 md:gap-2'>

                    {/*}
                    <div className='   bg-white rounded-[17px] shadow border border-black border-opacity-10'>
                        <div className='flex flex-col'>
                            <div className='w-full h-[211px]'>
                                <img className='h-full w-full' src="/rectangle-517@2x.png" alt="2" />
                            </div>
                            <div className='pt-6  flex flex-col px-6'>
                                <div className="bg-slate-100 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border border-blue-600 border-opacity-10 justify-center items-center gap-2.5 flex flex-row">
                                    <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Buisness</div>
                                </div>
                                <div className="pt-4 text-black text-5xl font-bold font-['DM Sans'] capitalize">Creating Intuitive Products that benefit people</div>
                                <div className="pt-4">
                                    <span className=" text-black text-opacity-60 text-sm font-normal font-['DM Sans'] leading-[25px]">In 2016, A Medium Corporation acquired the rich media embedding platform Embedly, a provider of content integration services for numerous websites, including Medium itself... </span><span className="text-blue-600 text-sm font-normal font-['DM Sans'] leading-[25px]">Read more</span>
                                </div>
                                <div className="pt-6 ml-auto text-black text-opacity-60 text-sm font-medium font-['DM Sans'] leading-[25px]">25 January, 2023</div>
                            </div>
                        </div>
                    </div>
                    {*/}

                    <div className=" max-w-[336px] min-h-[430px] pb-10 pt-7 px-10 rounded-mid bg-white shadow-[0px_4px_43px_rgba(174,_174,_174,_0.3)] box-border  border-[2px] border-solid border-mediumslateblue-500">
                        <div className='flex flex-col  gap-5'>
                            <b className="pb-2 text-[1.5rem] leading-[1.56rem] capitalize text-midnightblue">Resource Type</b>
                            <div className='flex gap-2'>
                                <img className='' src= "heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-books</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Journals</div>
                            </div>
                            <div className='flex gap-2'> 
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Magazine</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>Case Reports</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>Videos</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Newsletters</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Newspapers</div>
                            </div>
                        </div>    
                    </div>       
                    

                    
                    <div className="max-w-[336px] min-h-[430px] pb-10 pt-7 px-10 rounded-mid bg-white shadow-[0px_4px_43px_rgba(174,_174,_174,_0.3)] box-border  border-[2px] border-solid border-mediumslateblue-500">
                        <div className='flex flex-col gap-5'>
                            <b className="pb-2 text-[1.5rem] leading-[1.56rem] capitalize text-midnightblue">Indexing</b>

                            {indexing.map((item,index)=> (
                                <div key={index} className='flex gap-2'>
                                    <img className='' src= "heroiconssolidcheckcircle.svg" alt="icon" />
                                    <div className='font-dm-sans'>{item}</div>
                                </div>
                            ))

                            }
                         
                {/*}
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>Videos</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Newsletters</div>
                            </div>
                            <div className='flex gap-2'>
                                <img src="heroiconssolidcheckcircle.svg" alt="icon" />
                                <div>E-Newspapers</div>
                            </div>
                {*/}
                        </div>    
                    </div>    
                </div>
            </div>
           
        </div>
      
    </div>
  )
}

export default Offerings
