import React, { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Navigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {
  Education,
  AGRICULTURE,
  ARCHITECTURE,
  Forensic,
  Law,
  Management,
  Engineering,
  Physiotherapy,
  DNB,
  Basic,
  Ayush,
  Allied,
  Pharmacy,
  Nursing,
  Dental,
  Medical,
  Homeopathy,
  Masscommmedia,
  HUMANITYANDSOCIALSCIENCE
} from '../../constants';
import LaptopCategories from '../LaptopCategories';
import MobileLogin from '../MobileLogin';
import LaptopLogin from '../LaptopLogin';

const NavbarPage = ({ img, title, filename }) => {


  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);


  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const both = () => {
    handleExpanded();
    handleServices();
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }


  const handleDownload = () => {
    const fileUrl = '/files/' + filename + '.pdf'; // Replace with the path to your file inside the public folder

    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = filename + '.pdf'; // Set the file name here

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }


  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }

  return (
    <div className='relative  h-[50.05rem] text-white font-dm-sans'>

      <div className='max-mobile:hidden block absolute w-full h-[50.05rem]'>
        <img
          className=" w-full h-[50.05rem] object-cover"
          alt=""
          src={img}
        />
      </div>


      <div className="w-10/12 max-mobile:hidden block absolute [background:linear-gradient(90deg,_#0a1847,_#0a1847_48.02%,_rgba(10,_24,_71,_0.56)_71.98%,_rgba(0,_0,_0,_0))] md:w-9/12  h-[50.05rem] "></div>



      <div className="z-[999] fixed max-mobile:hidden block  w-full   bg-[#09153f] [backdrop-filter:blur(14px)] h-[3.63rem]   text-[0.81rem]">
        <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
          <div className='pt-2'>
            <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
          </div>
          <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>
            <Link className='no-underline text-white' to='/'><div className='hover:text-green-600 capitalize font-medium hover:cursor-pointer '>Home</div></Link>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='hover:text-green-600 flex items-center hover:cursor-pointer capitalize font-medium'>
              Our Services
              {
                expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
              }
              {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />}
            </div>
            <Link className='no-underline text-white' to='/#blogs'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></Link>

            <Link className='no-underline text-white' to='/#resources'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></Link>
            <Link className='no-underline text-white' to='/contact'><div className='hover:text-green-600 capitalize font-medium hover:cursor-pointer'>Contact</div></Link>
          </div>
          <div className="flex gap-2 ">
            <Link to='/trail' className='no-underline text-white'>
              <button
                className="hover:bg-blue-600 hover:text-white  w-full py-2.5  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
              >
                Get Free Trial

              </button>
            </Link>
            <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
              <LaptopLogin />
            </a>
            {/* <Link to='/login' className='no-underline text-white'>
                        <LaptopLogin/>                        
                        </Link> */}
            {
              Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                <img src="x-close.svg" alt="img" />
              </div>) :

                (<div className="  lg:hidden block text-white">
                  <MenuIcon onClick={handleMobServices} className='' />
                </div>)
            }
          </div>
        </div>
      </div>

      <div className={` ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''} max-mobile:hidden block  absolute md:max-h-[800px] lg:pl-28 pl-10 max-sm:pl-4  max-h-[600px] top-[20%] `}>

        <div className="mb-16 flex gap-2 text-white text-[13px] font-medium font-['DM Sans'] capitalize">
          <Link to='/' className='no-underline text-white'><div className="hover:opacity-[1] hover:underline hover:text-green-600  capitalize font-medium inline-block  opacity-[0.2]">Home</div></Link>
          <div className=" capitalize font-medium inline-block ">/</div>
          <div className="capitalize font-medium">{title}</div>
        </div>

        <div className='  flex flex-col '>
          <b className=" font-bold md:text-[4.56rem] text-[2rem] md:leading-[5.81rem] tracking-[-1px] capitalize ">
            {title}
          </b>


          <div className="md:text-[1rem]  max-sm:text-[3.2vw] text-[12px] mt-4 md:w-4/5  font-medium  ">
            Your trusted source for cutting-edge technical research and
            more.
          </div>


          <div className="flex gap-4 max-sm:gap-0 md:w-4/5 md:mt-10 mt-5 ">
            <div className="  rounded-51xl bg-mediumslateblue-200 flex flex-row items-center justify-center py-[1rem] px-[1.5rem] ">
              <Link to='/trail' className='no-underline text-white'>
                <div className="hover:text-green-400 leading-[1.25rem] capitalize font-medium">
                  Get Free Trial
                </div>
              </Link>
            </div>
            <div onClick={handleDownload} className=" hover:bg-blue-600 hover:cursor-pointer gap-2 flex items-center rounded-51xl box-border px-4  border-[1px] border-solid border-gray-600">
              <img
                className=" "
                alt=""
                src="/download.svg"
              />
              <div className="  md:text-[1rem] text-[12px] max-sm:text-[3.2vw] leading-[1.25rem] capitalize font-medium">
                Download Brochure
              </div>
            </div>
          </div>



          <div className="hidden md:block mt-14 leading-[1.25rem] capitalize font-medium">
            Contact Our Sales Executive here :
          </div>
          <div className='hidden   mt-4 md:flex  gap-4 max-sm:flex-col'>
            <div className=" leading-[1.25rem] md:text-base text-sm flex items-center gap-2  capitalize font-medium">
              <img
                className="  max-sm:h-[13px] max-sm:w-[13px] "
                alt=""
                src="/group-2357.svg"
              />
              + 91 9899 8610 71
            </div>
            <div className='leading-[1.25rem]  md:text-base text-sm  font-medium flex items-center gap-2'>
              <img
                className=" "
                alt=""
                src="/heroiconsoutlineenvelope.svg"
              /> Info@avags.in
            </div>
          </div>






        </div>


      </div>


      <div className={` top-[5rem] fixed z-[999] flex   gap-3 justify-center w-full  overflow-x-hidden md:max-h-[936px] max-h-[600px] ${services ? 'block' : 'hidden'}`}>
        <LaptopCategories handleServices={handleServices} />

      </div>








      <div className={`block  relative md:hidden `}>
        <img
          className=" w-full  h-[800px] object-cover"
          alt=""
          src={img}
        />
        <div className="  h-full w-full  absolute bottom-0 left-0   [background:linear-gradient(350.02deg,_#0a1847,_#0a1847_12.28%,_rgba(10,_24,_71,_0.56)_51.39%,_rgba(0,_0,_0,_0))]  "></div>
        <div className='h-[4rem] bgclrtrail  fixed z-[999] bg-[#09153f] w-full top-0 backdrop-filter-cust' style={{ zIndex: 1 }}>
          <div className='h-full   flex justify-between items-center pl-[4%] pr-[4%] '>
            <div className='pt-2'>
              <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
            </div>
            <div className="flex gap-2 ">
              <   Link to='/trail' className='no-underline text-white'>
                <button
                  className="hover:text-white hover:bg-blue-600   w-full py-2  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                >
                  Get Free Trial
                </button>
              </Link>
              <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
                <LaptopLogin />
              </a>
              {/* <Link to='/login' className='no-underline text-white'>
                <LaptopLogin />
              </Link> */}
              {
                Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                  <img src="x-close.svg" alt="img" />
                </div>) :

                  (<div className="  lg:hidden block text-white">
                    <MenuIcon onClick={handleMobServices} className='' />
                  </div>)
              }

            </div>
          </div>
        </div>

        <div className='  max-sm:pl-6  pl-10 font-dm-sans absolute text-black  top-[11%] left-0'>
          <div className=" flex gap-2  text-[13px] font-medium font-['DM Sans'] capitalize">
            <Link to='/' className='no-underline text-white'><div className="hover:opacity-[1] hover:underline hover:text-green-600   capitalize font-medium text-[0.812rem]  opacity-[0.2]">Home</div></Link>
            <div className=" capitalize text-white font-medium">/</div>
            <div className="capitalize text-white  font-medium text-[0.812rem]">{title}</div>
          </div>
        </div>

        <div className={`absolute text-white  bottom-[20%] left-0 ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}`}>
          <div className='max-sm:pl-6 pl-10 flex flex-col'>
            <b className="w-10/12 max-sm:w-full tracking-[-1px] inline-block text-[3rem] max-mobile:text-[2rem] font-dm-sans">{title}</b>
            <div className=" mt-6 font-dm-sans pr-[4%] text-[1rem] leading-[2rem] font-medium text-custom inline-block ">Your trusted source for cutting-edge technical research and more. </div>
            <div className="flex  flex-wrap gap-2 mt-6">
              <Link to='/trail' className='no-underline text-white' >
                <button className="hover:text-green-400 max-sm:text-[12px]  whitespace-nowrap py-3 px-4 text-center font-dm-sans  bg-mediumslateblue-200 text-white  font-medium font-[DM Sans] leading-tight   border border-mediumslateblue-500 rounded-11xl "
                >Get Free Trial</button>
              </Link>




              <button onClick={handleDownload} className="hover:bg-blue-600   max-sm:text-[12px]   flex bg-transparent  font-dm-sans gap-1 items-center py-1 px-4 whitespace-nowrap  text-white  font-medium font-[DM Sans] leading-tight border-[1px] border-solid border-gray-600  rounded-11xl ">

                <img
                  className=" "
                  alt="logo"
                  src="/download.svg"
                />
                <div> Download Brochure </div>
              </button>




            </div>
          </div>
        </div>

      </div>

      {
        Mobservices &&

        (<MobileLogin expanded={expanded} handleExpanded={handleExpanded} handleMobServices={handleMobServices} />)
      }


    </div>
  )
}

export default NavbarPage
