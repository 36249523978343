import React from 'react'

const OurCommitment = () => {
  return (
    <div className='md:pt-20 font-dm-sans'>
        

        <div className="ml-cust mr-cust-10   rounded-51xl box-border w-28 h-[1.63rem] flex flex-row items-center justify-center py-[1.2rem] px-[1.5rem] text-right text-[0.75rem] text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500">
          <div className="leading-[1.25rem] capitalize font-medium">Statistics</div>
        </div>
        <div className="pt-4  ml-cust-67  mr-cust-10 grid grid-cols-1 lg:grid-cols-2 gap-16">


            <div className=" flex flex-col">
                
                <div className="min-breakpoint:text-[2.3rem] min-breakpoint:font-bold md:text-[2.5rem] md:font-bold xl:font-medium xl:text-[3.12rem] text-[3.12rem] capitalize font-medium text-black  ">
                    Our resource Bank
                </div>
                <div className="text-[1rem] pt-5 pl-1 leading-loose font-medium text-gray-1000 ">
                Our commitment to excellence drives us to add new journals, e-books, articles, and videos every month, ensuring that our users always have access to the latest and most valuable resources. 
                </div>

                <div className="mt-6 rounded-51xl box-border w-[154.50px]  text-center px-6   py-4   border-[1px] border-solid border-mediumslateblue-500 ">
                    <div className="text-center  font-medium  font-['DM Sans'] text-blue-600   leading-tight">
                        Learn More
                        <img className="text-blue-600 pl-2 w-[0.72rem] h-[0.63rem]" alt="img" src="/vector3.svg"></img></div>
                </div>
            </div>




            <div className=' '>
            <div className="bg-grey-500   flex flex-col gap-8">
                    
                    <div className=' min-mobile:flex-row flex flex-col   gap-4   '>
                        <div className=' w-1/2 flex items-center'>
                            <img
                            className="  "
                            alt=""
                            src="/group-2265.svg"
                            />
                            <div className=" hover:text-blue-600 text-black text-[44px] md:text-[40px]  lg:text-[47px] font-bold font-['DM Sans'] ">25k+
                                <div className="text-gray-1000 hover:text-blue-600  lg:text-[1rem]  text-[1rem] leading-[1.5rem] font-medium  ">
                                Records 
                                </div>
                            </div>      
                        </div>

                        <div className='  w-1/2  flex items-center'>
                            <img
                            className=""
                            alt=""
                            src="/group-2244.svg"
                            />
                            <div className=" hover:text-blue-600 pl-2 text-[44px] md:text-[40px]  lg:text-[47px] text-black font-bold font-['DM Sans'] capitalize">1000+
                                <div className=" hover:text-blue-600 text-gray-1000 lg:text-[1rem] text-[1rem] leading-[1.5rem] font-medium  ">
                                Publisher
                                </div>
                            </div>      
                        </div>
                    </div>


                    <div className='flex gap-4  min-mobile:flex-row  flex-col '>
                        <div className=' w-1/2 flex items-center'>
                            
                            <img
                            className=""
                            alt=""
                            src="/group-2243.svg"
                            />
                            <div className=" hover:text-blue-600 pl-2 text-[44px] ] lg:text-[47px] md:text-[40px] text-black font-bold font-['DM Sans'] capitalize">10k
                                <div className="hover:text-blue-600  text-gray-1000 lg:text-[1rem]  text-[1rem]   lg:leading-[1.5rem] font-medium  ">
                                Videos
                                </div>
                            </div>      
                            
                        
                        </div>

                        <div className=' w-1/2 flex  items-center'>
                            <img
                            className="   "
                            alt=""
                            src="/group-2266.svg"
                            />
                            <div className=" hover:text-blue-600 pl-2 text-[44px]  lg:text-[47px] md:text-[40px] text-black font-bold font-['DM Sans'] capitalize">5000+
                                <div className="hover:text-blue-600  text-gray-1000 lg:text-[1rem]  text-[1rem] lg:leading-[1.5rem] font-medium  ">
                                eBooks
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>
                </div>
          
        </div>    


{/*}
        <div className="absolute left-[0rem] w-[90rem] h-[28.19rem] text-[2.94rem] text-gray-300">
            <div className="absolute top-[6.5rem] left-[46.5rem] text-[3.44rem] tracking-[-1px] capitalize font-medium text-black inline-block w-[36rem]">
            Our resource Bank
            </div>
            <div className="absolute top-[12.25rem] left-[46.5rem] text-[1rem] leading-[2rem] font-medium text-gray-1000 inline-block w-[37.56rem]">
            Our commitment to excellence drives us to add new journals, e-books,
            articles, and videos every month, ensuring that our users always have
            access to the latest and most valuable resources."
            </div>
            <div className="absolute top-[4.38rem] left-[46.5rem] rounded-51xl box-border w-[5.13rem] h-[1.63rem] flex flex-row items-center justify-center py-[1rem] px-[1.5rem] text-right text-[0.75rem] text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500">
                <div className="relative w-[3.38rem] h-[1.25rem]">
                    <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                    Statistics
                    </div>
                </div>
            </div>
            <div className="absolute top-[7.81rem] left-[6.63rem] w-[19.81rem] h-[5.31rem] text-mediumslateblue-200">
                <div className="absolute top-[0rem] left-[6.38rem] w-[13.44rem] h-[5.31rem]">
                    <div className="text-blue-600 text-[47px] font-bold font-['DM Sans'] capitalize">25k+</div>
                    <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] font-medium inline-block w-[13.44rem]">
                    E-Journals
                    </div>
                </div>
                <img
                    className="absolute h-[108.24%] w-[29.02%] top-[2.35%] right-[73.82%] bottom-[-10.59%] left-[-2.84%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/group-2265.svg"
                />
            </div>
            <div className="absolute top-[17.31rem] left-[6.63rem] w-[15.75rem] h-[5.31rem]">
                <div className="absolute top-[0rem] left-[6.06rem] w-[9.69rem] h-[5.31rem]">
                <b className="absolute top-[0rem] left-[0rem] capitalize">1000+</b>
                <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] capitalize font-medium text-gray-1000 inline-block w-[9.69rem]">
                Ebooks
                </div>
                </div>
                <img
                    className="absolute h-[108.24%] w-[36.51%] top-[3.53%] right-[67.06%] bottom-[-11.76%] left-[-3.57%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/group-2266.svg"
                />
            </div>
            <div className="absolute top-[7.88rem] left-[26.19rem] w-[16.19rem] h-[5.31rem]">
                <div className="absolute top-[0rem] left-[6.5rem] w-[9.69rem] h-[5.31rem]">
                <b className="absolute top-[0rem] left-[0rem] capitalize">300+</b>
                <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] font-medium text-gray-1000 inline-block w-[9.69rem]">
                Publishers
                </div>
                </div>
                <img
                    className="absolute h-[108.24%] w-[35.52%] top-[3.53%] right-[67.95%] bottom-[-11.76%] left-[-3.47%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/group-2244.svg"
                />
            </div>
            <div className="absolute top-[17.31rem] left-[26.19rem] w-[17.06rem] h-[5.31rem]">
                <b className="absolute top-[0rem] left-[6.5rem] capitalize">10k</b>
                <div className="absolute top-[3.81rem] left-[6.5rem] text-[1rem] leading-[1.5rem] capitalize font-medium text-gray-1000 inline-block w-[10.56rem]">
                    Valuable resources
                </div>
                <img
                    className="absolute h-[108.24%] w-[33.7%] top-[3.53%] right-[69.6%] bottom-[-11.77%] left-[-3.3%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/group-2243.svg"
                />
            </div>
        </div>
  {*/}
    </div>
  )
}

export default OurCommitment






{/*
  <div className="absolute top-[235.5rem] left-[calc(50%_-_601px)] flex flex-col items-center justify-center text-center">
        <div className="relative rounded-7xl bg-mediumslateblue-100 w-[75.13rem] h-[21.63rem] overflow-hidden shrink-0">
          <img
            className="absolute top-[0rem] left-[12.38rem] w-[49.48rem] h-[29.86rem]"
            alt=""
            src="/group-2234.svg"
          />
          <div className="absolute top-[11.13rem] left-[19.13rem] leading-[2rem] font-medium inline-block w-[38rem]">
            Start your journey of getting study resource at your fingertips in
            digital system
          </div>
          <div className="absolute top-[3.44rem] left-[17.94rem] text-[2.5rem] tracking-[-1px] capitalize font-medium inline-block w-[38.38rem]">
            <p className="m-0">Get started With A</p>
            <p className="m-0"> free trial</p>
          </div>
          <div className="absolute top-[14.94rem] left-[33.06rem] rounded-51xl bg-white flex flex-row items-center justify-start py-[1rem] px-[1.5rem] text-right text-mediumslateblue-200">
            <div className="relative w-[6.03rem] h-[1.25rem]">
              <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                Book Now
              </div>
              <img
                className="absolute top-[0.31rem] left-[5.31rem] w-[0.72rem] h-[0.63rem]"
                alt=""
                src="/vector2.svg"
              />
            </div>
          </div>
        </div>
</div> */}