import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { scroller } from 'react-scroll';
import MobileCategories from './MobileCategories';
import { useEffect } from 'react';

const MobileLogin = ({expanded,handleExpanded,handleMobServices}) => {
 
  return (
    <>
      <div   className='z-50 max-h-[80vh] overflow-y-scroll max-mobile:top-[4rem] text-white font-dm-sans transition-all duration-500 ease-in  fixed left-0 right-0 top-[3.63rem]   gap-7 flex py-20 px-10 flex-col lg:hidden bg-[#0A1847] '>
                    
                    <div className=' w-full'>
                    <Link className='no-underline text-white' to='/'><div className='hover:text-green-600 hover:cursor-pointer capitalize font-dm-sans font-medium'>Home</div></Link>
                      <img className='mt-9 w-full' src="Line-2.svg" alt="ig" />  
                     </div>    
                 
                    <div>
                      <div onClick={handleExpanded}  className='inline-flex gap-2 items-center '>
                        <div className='hover:text-green-600 hover:cursor-pointer capitalize font-dm-sans font-medium'>Our Services
                          {
                            expanded  && <img className='ml-2' src='Group-22135.svg' alt='img'/>
                          }
                          {
                            expanded && (
                              <div className='max-h-[80vh] overflow-y-scroll'>
                              <MobileCategories handleMobServices={handleMobServices}/>
                            </div>
                            )
                          }
                        
                        </div>
                        {expanded===false && <img src="Group-2213.svg" alt="img" /> } 
                      </div>
                   
                      <div className='flex flex-col'>
                          <div></div>
                      </div>
                      <img src="Line-2.svg" className='mt-9 w-full' alt="" />  
                    </div>
    
                    <div>
                <Link className='no-underline text-white' to='/#blogs'><div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>News & Blogs </div></Link>
                  <img src="Line-2.svg" className=' mt-9 w-full' alt="img" />  
                </div>


                <div>
                <Link className='no-underline text-white' to='/#resources'><div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>Resources </div></Link>
                  <img src="Line-2.svg" className=' mt-9 w-full' alt="img" />  
                </div>
                
    
                    <div>
                      <Link className='no-underline text-white' to='/contact'><div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>Contact Us</div></Link>
                      <img src="Line-2.svg" className=' mt-9 w-full' alt="" />  
                    </div>
                    <Link to='/login' className='no-underline text-white'>
                    <button 
                      className="hover:text-green-400  w-full py-3 px-8   capitalize whitespace-nowrap bg-blue-600 max-w-[114px] text-white     rounded-11xl "
                      >
                      Login
                    </button>
                    </Link>
                  </div>
    </>
  )
}

export default MobileLogin
