import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import FooterCust from "../components/HomePage/FooterCust"
import LoginFooter from './LoginFooter';
import {
  Education,
  AGRICULTURE,
  ARCHITECTURE,
  Forensic,
  Law,
  Management,
  Engineering,
  Physiotherapy,
  DNB,
  Basic,
  Ayush,
  Allied,
  Pharmacy,
  Nursing,
  Dental,
  Medical,
  Homeopathy,
  Masscommmedia,
  HUMANITYANDSOCIALSCIENCE
} from '../constants'
import LaptopCategories from '../components/LaptopCategories';
import MobileLogin from '../components/MobileLogin';
import LaptopLogin from '../components/LaptopLogin';


const Login = () => {
  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);

  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }
  const both = () => {
    handleExpanded();
    handleServices();
  }

  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }


  const [isLogin, setIsLogin] = useState(false);
  const handleLoginClick = () => {
    setIsLogin(true);
  };

  const handleGoBackClick = () => {
    setIsLogin(false);
  };

  return (
    <div className=' font-dm-sans'>

      <div className="hidden fixed z-[999] lg:block w-full text-white  navbarbg font-dm-sans  text-[0.81rem]">
        <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
          <div className='pt-2'>
            <img className='max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />

          </div>
          <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>
            <Link to='/' className='no-underline text-white'><div className='hover:text-green-400 capitalize font-medium hover:cursor-pointer '>Home</div></Link>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='hover:text-green-400  flex items-center hover:cursor-pointer capitalize font-medium'>
              Our Services
              {
                expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
              }
              {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />} </div>
            <Link className='no-underline text-white' to='/#blogs'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></Link>

            <Link className='no-underline text-white' to='/#resources'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></Link>
            <Link to='/contact' className='no-underline text-white'><div className='hover:text-green-400 capitalize font-medium hover:cursor-pointer'>Contact</div></Link>
          </div>



          <div className="flex gap-2 ">
            <Link to='/trail' className='no-underline text-white'><button
              className="w-full py-2 hover:bg-blue-600 hover:text-white  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
            >
              Get Free Trial

            </button>
            </Link>
            {/* <Link to='/login' className='no-underline text-white'> */}
            <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
              <LaptopLogin />
            </a>
            {/* </Link> */}
            <div className="lg:hidden block">
              <MenuIcon />
            </div>

          </div>
        </div>
      </div>


      <div className={`fixed z-[999] top-[5rem] font-dm-sans text-white  flex   gap-3 justify-center w-full  overflow-x-hidden md:max-h-[936px] max-h-[600px] ${services ? 'block' : 'hidden'}`}>
        <LaptopCategories handleServices={handleServices} />

      </div>







      {
        Mobservices &&
        (<MobileLogin expanded={expanded} handleExpanded={handleExpanded} handleMobServices={handleMobServices} />)

      }

      <div className={`  grid lg:grid-cols-2 grid-cols-1 ${services ? 'blur-text' : ''}`}>
        <div className={`relative`}>
          <img className={` ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}   w-full lg:h-full h-[1000px] object-cover`} alt="" src="/imagelogin.png" />
          <div className='lg:hidden fixed z-[999] block bg-black  h-[4rem]  top-0 left-0  w-full  ' style={{ zIndex: 1 }}>
            <div className='h-full   flex justify-between items-center pl-[4%] pr-[4%] '>
              <div className='pt-2'>
                <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
              </div>
              <div className="flex gap-2 ">
                <Link to='/trail' className='no-underline text-white'>
                  <button
                    className="hover:text-white hover:bg-blue-600   w-full py-2  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                  >
                    Get Free Trial
                  </button>
                </Link>
                <LaptopLogin />
                {
                  Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                    <img src="x-close.svg" alt="img" />
                  </div>) :

                    (<div className="  lg:hidden block text-white">
                      <MenuIcon onClick={handleMobServices} className='' />
                    </div>)
                }

              </div>
            </div>
          </div>
          <div className='' style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', /* Center horizontally */
            alignItems: 'center', /* Center vertically */
            /*textAlign: 'center',*/
            gap: '20px'
          }}
          >

            <div className="w-6/12 -mt-[100px] hidden   lg:block text-center text-white text-base font-medium font-['DM Sans'] leading-[25px]">
              <div className=''>
                <img className='w-[157] h-[54px] z-50 ' src='dvllogo.png' alt="img" />
              </div>
              <div className='mt-6'>Dvirtual Library (DVL) is a leading digital resource hub dedicated to empowering excellence in medical education and research. </div>
            </div>



            <div className='lg:hidden block bg-white w-11/12 rounded-[15px] border border-blue-600 border-opacity-30'>
              <div className={`px-[6%] py-[6%] ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}`}>
                {
                  isLogin === false ? (<div className='flex flex-col '>

                    <div className='flex gap-2'>
                      <div className={`${isLogin === false ? 'bg-mediumslateblue-200 text-white' : 'bg-slate-100 text-blue-500'} flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap  max-w-[64px]   font-medium leading-tight border border-mediumslateblue-500  rounded-11xl`} >
                        <div >Step 1</div>
                      </div>
                      <div className={`${isLogin ? 'bg-mediumslateblue-200 text-white' : 'bg-slate-100 text-blue-500'} flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap  max-w-[64px]  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                        <div>Step 2</div>
                      </div>

                    </div>
                    <b className="max-sm:text-[1.5rem] mt-6 tracking-[-1px] capitalize inline-block text-[1.88rem]">Welcome Back 👋</b>
                    <div class="max-sm:text-[0.77rem]  text-[0.88rem] mt-1 leading-[1.56rem] font-medium text-gray-100">Please find your university below</div>

                    <div class="mt-12 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Select University</div>
                    <input
                      className="md:pl-4 xl:w-[20rem]  mt-3 py-4 w-full  bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                      type="text"
                      placeholder="Enter University Name"
                    />

                    <button
                      onClick={handleLoginClick}
                      className=" xl:w-[20rem] mt-6 bg-mediumslateblue-200  py-3 w-full text-[1rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                    >Next
                    </button>
                  </div>) :

                    (<div className='flex flex-col'>

                      <div className='flex gap-2'>
                        <div className={`flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap bg-slate-200 max-w-[64px] text-blue-500  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                          <div>Step 1</div>
                        </div>
                        <div className={`flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap bg-mediumslateblue-200 max-w-[64px] text-white  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                          <div>Step 2</div>
                        </div>

                      </div>
                      <b className="max-sm:text-[1.5rem]  mt-6 tracking-[-1px] capitalize inline-block text-[1.88rem]">Welcome Back 👋</b>
                      <div class="max-sm:text-[0.7rem]  text-[0.88rem] mt-1 leading-[1.56rem] font-medium text-gray-100">Please enter your login credentials here</div>

                      <div class="mt-8 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Username</div>
                      <input
                        className="md:pl-4  xl:w-[20rem] mt-3 py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                        type="text"
                        placeholder="Enter Username"
                      />

                      <div class="mt-6 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Password</div>
                      <input
                        className="md:pl-4 mt-3 xl:w-[20rem] py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                        type="password"
                        placeholder=""
                      />

                      <div style={{ marginLeft: 'auto', marginTop: '12px', textAlign: 'center', color: '#FF443A', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: '500', lineHeight: '12px', letterSpacing: '0.12px', wordWrap: 'break-word' }}>Forgot Password?</div>


                      <div className='flex gap-2'>
                        <button
                          onClick={handleGoBackClick}
                          className=" mt-6 bg-mediumslateblue-200  py-3 w-full text-[0.88rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl "
                          type="submit">Go Back
                        </button>

                        <button

                          className=" mt-6 bg-mediumslateblue-200 text-center  py-2.5 w-full text-[1rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl "
                        >Sign In
                        </button>
                      </div>
                    </div>
                    )
                }
                <div className="mt-20 gap-4 flex px-[2rem] py-[2rem] flex-col bg-black bg-opacity-90 rounded-tl-[11px] rounded-tr-[11px] rounded-bl-[15px] rounded-br-[15px] border border-black border-opacity-5">
                  <img className='w-[104px]  h-[37px]' src="image-4@2x.png" />
                  <div className=" text-white text-base font-medium font-['DM Sans'] text-[0.8rem] leading-[25px]">Dvirtual Library (DVL) is a leading digital resource hub dedicated to empowering excellence in medical education and research. </div>
                </div>
              </div>

            </div>
            {/*<img style={{}} src="logo.png" /> /*/}

          </div>





        </div>
        <div>
          <div className='lg:flex   hidden h-full w-full justify-center items-center'>


            {
              isLogin === false ? (<div className='flex flex-col'>

                <div className='flex gap-2'>
                  <div className={`${isLogin === false ? 'bg-mediumslateblue-200 text-white' : 'bg-slate-100 text-blue-500'} flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap  max-w-[64px]   font-medium leading-tight border border-mediumslateblue-500  rounded-11xl`} >
                    <div >Step 1</div>
                  </div>
                  <div className={`${isLogin ? 'bg-mediumslateblue-200 text-white' : 'bg-slate-100 text-blue-500'} flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap  max-w-[64px]  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                    <div>Step 2</div>
                  </div>

                </div>
                <b className="mt-6 tracking-[-1px] capitalize inline-block text-[1.88rem]">Welcome Back 👋</b>
                <div class="text-[0.88rem] mt-1 leading-[1.56rem] font-medium text-gray-100">Please find your university below</div>

                <div class="mt-12 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Select University</div>
                <input
                  className="md:pl-4 xl:w-[20rem]  mt-3 py-4 w-full  bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                  type="text"
                  placeholder="Enter University Name"
                />

                <button
                  onClick={handleLoginClick}
                  className=" xl:w-[20rem] mt-6 bg-mediumslateblue-200  py-3 w-full text-[1rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                >Next
                </button>
              </div>) :

                (<div className='flex flex-col'>

                  <div className='flex gap-2'>
                    <div className={`flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap bg-slate-200 max-w-[64px] text-blue-500  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                      <div>Step 1</div>
                    </div>
                    <div className={`flex px-2 w-1/2 justify-center py-2.5  text-[0.88rem] whitespace-nowrap bg-mediumslateblue-200 max-w-[64px] text-white  font-medium leading-tight border border-mediumslateblue-500  rounded-11xl `}>
                      <div>Step 2</div>
                    </div>

                  </div>
                  <b className="mt-6 tracking-[-1px] capitalize inline-block text-[1.88rem]">Welcome Back 👋</b>
                  <div class="text-[0.88rem] mt-1 leading-[1.56rem] font-medium text-gray-100">Please enter your login credentials here</div>

                  <div class="mt-8 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Username</div>
                  <input
                    className="md:pl-4  xl:w-[20rem] mt-3 py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                    type="text"
                    placeholder="Enter Username"
                  />

                  <div class="mt-6 ml-1 text-[0.81rem] tracking-[0.01em] leading-[100%] font-medium">Password</div>
                  <input
                    className="md:pl-4 mt-3 xl:w-[20rem] py-4 w-full bg-[#F7FBFF] placeholder-[#AAAAAA] font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl p-2"
                    type="password"
                    placeholder=""
                  />

                  <div style={{ marginLeft: 'auto', marginTop: '12px', textAlign: 'center', color: '#FF443A', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: '500', lineHeight: '12px', letterSpacing: '0.12px', wordWrap: 'break-word' }}>Forgot Password?</div>


                  <div className='flex gap-2'>
                    <button
                      onClick={handleGoBackClick}
                      className=" mt-6 bg-mediumslateblue-200  py-3 w-full text-[0.88rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl "
                      type="submit">Go Back
                    </button>

                    <button

                      className=" mt-6 bg-mediumslateblue-200 text-center  py-2.5 w-full text-[1rem] font-dm-sans text-white font-medium font-[DM Sans] leading-tight box-border border-[1px] border-solid border-mediumslateblue-500 rounded-11xl "
                    >Sign In
                    </button>
                  </div>
                </div>)
            }


          </div>
        </div>

      </div>
      <div className='z-50 block lg:hidden -mt-[5px] w-full h-[5px] bg-blue-900'></div>
      <div className='text-white  hidden lg:block  font-dm-sans overflow-hidden text-[1rem]'><FooterCust /></div>
      <div className='text-white  lg:hidden block  font-dm-sans overflow-hidden text-[1rem]'><LoginFooter /></div>
    </div>
  )
}

export default Login
