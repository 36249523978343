import { useEffect, useState } from "react";

const Testimonial = () => {

  const [childbox,setChildBox]=useState({});
  const [box,setBox]=useState({});

  const getBox=()=>{
    console.log('inside getBox Testimonial');
     let box2 = document.querySelector('.testimonial-container');
     let box3 = document.querySelector('.testimonial-item');
     setBox(box2);
     setChildBox(box3);
  }

  useEffect(()=>{
    getBox();

  },[]);

 
  console.log('box',box);
  const btnpressprev = () => {
      let width = box.clientWidth;
      let childwidth = childbox.clientWidth;
      console.log('width',width);
      console.log('childwidth',childwidth);
      box.scrollLeft = box.scrollLeft - childwidth-32;
      console.log(width)
  }

  const btnpressnext = () => {
      let width = box.clientWidth;
      let childwidth = childbox.clientWidth;
      console.log('width',width);
      console.log('childwidth',childwidth);
      box.scrollLeft = box.scrollLeft + childwidth+32;
      console.log(width)
  }



  return (
    <div className=' my-4   '>
        <div className=' ml-cust-67      pt-10 mr-cust-10'>
            <div className=" text-[3.44rem] ">
                <div className=" bg-white max-mobile:mb-4 w-[80px] h-[5px] px-6 py-4 rounded-[70px] border-[1px] box-border border-solid border-mediumslateblue-500  justify-center items-center gap-2.5 flex flex-row">
                    <div className="text-center text-blue-600 text-xs font-medium font-['DM Sans'] capitalize leading-tight">Reviews</div>
                </div>
                <div className='mt-2 flex justify-between flex-wrap w-full'>      
                    
                    <div className="capitalize lg:text-[3.44rem] min-breakpoint:text-[2rem] min-breakpoint:font-bold  md:text-[2.9rem]   font-medium font-['DM Sans'] text-black">what clients say about us </div>
                   
                </div>
                <div className="pt-1  text-black text-opacity-60 text-base font-medium font-['DM Sans'] leading-loose">
                        Take a look at feedbacks from various Universities
                </div>
                <div className=" max-mobile:mt-4 flex ">
                  <div className="ml-auto flex gap-3">
                                <div onClick={()=>{btnpressprev()}}  className="hover:bg-slate-400 hover:cursor-pointer flex justify-center items-center  h-16 w-16 bg-white border-[1px] border-solid border-slate-200 rounded-full " >
                                <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className="text-white text-[1rem] font-dm-sans ">
                                    <img src="bi_arrow-left-short-1.svg" alt="arrow" />
                                </span>
                                </div>
                            
                                <div onClick={()=>{btnpressnext()}}  className="hover:bg-blue-800  hover:cursor-pointer flex justify-center items-center h-16 w-16 bg-blue-900 rounded-full" >
                                    <span style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className=" text-white ">
                                        <img src="bi_arrow-left-short.svg" alt="arrow" />
                                    </span>
                                </div>
                  </div>
                       
                </div>
            </div>
            <div className=' testimonial-container pl-0 md:pt-14 pt-10 flex gap-8  overflow-x-hidden'>
                <div className='min-mobile:px-8 md:px-10  px-2   testimonial-item min-w-[270px]   md:min-w-[477px] md:max-w-[477px] md:min-h-[300px] flex flex-col  rounded-11xl bg-mediumslateblue-300  box-border  pt-10 pb-20 '>
                    <div className='flex gap-4 md:gap-6 '>
                    <   div className=" ">
                            <img className="w-[85px] h-[70px] rounded-full" src="testi1.jpg" />
                        </div>
                        <div>
                            <div className=" text-[1rem] text-black md:text-[1.75rem] font-bold font-['DM Sans'] capitalize ">Dr. R. Ahmed Dental College & Hospital</div>
                            
                        </div>
                    </div>
                    <div className='max-mobile:px-1 pt-[57px]  text-[0.88rem] md:text-[1rem]   leading-[1.56rem] text-gray-900 '>  M/S Avags Information System is providing online/print journal’s subscription services at Dr. R. Ahmed Dental College & Hospital, Kolkata, from last 3 years. Our Business relationship with M/S Avags Information System has been very satisfactory and they have always come up to our expectations in terms of timely delivery and exceptionally good database for our users and university in best possible way.</div>
                </div>
               
                <div className='min-mobile:px-8 md:px-10  px-2   testimonial-item min-w-[270px]   md:min-w-[477px] md:max-w-[477px] md:min-h-[300px] flex flex-col  rounded-11xl bg-mediumslateblue-300  box-border  pt-10 pb-20 '>
                    <div className='flex gap-4 md:gap-6 '>
                      <div className=" ">
                            <img className="w-[85px] h-[70px] rounded-full" src="testi2.jpg" />
                        </div>
                        <div>
                            <div className=" text-[1rem] text-black md:text-[1.75rem] font-bold font-['DM Sans'] capitalize ">Government Medical College, Ratlam</div>
                          
                        </div>
                    </div>
                    <div className=' pt-[57px]  text-[0.88rem] md:text-[1rem]   leading-[1.56rem] text-gray-900 '>   With much pleasure we are writing to recommend the services of M/S Avags Information System, for providing ejournals /ebooks at Government Medical College, Ratlam from last 3 years. Our collaboration with Avags Information System has been very satisfactory & our expectations have always been fulfilled in terms of best services and time to time provided support, we look forward to work with Avags Information Systems in future also and would like to recommend their name as vendor to any Institution/University.</div>
                </div>
               
                <div className='min-mobile:px-8 md:px-10  px-2   testimonial-item min-w-[270px]   md:min-w-[477px] md:max-w-[477px] md:min-h-[300px] flex flex-col  rounded-11xl bg-mediumslateblue-300  box-border  pt-10 pb-20 '>
                    <div className='flex gap-4 md:gap-6 '>
                        <div className=" ">
                            <img className="w-[85px] h-[70px] rounded-full" src="testi3.jpg" />
                        </div>
                        <div>
                            <div className=" text-[1rem] text-black md:text-[1.5rem] font-bold font-['DM Sans'] capitalize "> Maharashtra Institute of Dental Sciences and Research </div>
                            <div className="pt-1 text-black text-base font-medium font-['DM Sans'] capitalize">Latur India</div>
                        </div>
                    </div>
                    <div className=' pt-6 text-[0.88rem] md:text-[1rem]   leading-[1.56rem] text-gray-900 '>   We would like to express our sincere appreciation for your service for DVL online Library and Print journal’s to us as one of our most reliable regular suppliers. Ever since we signed our contract, you have provided the highest quality products with even better customer service. Deliveries are often received earlier than expected, and you are quick to resolve any issues that arise.</div>
                </div>


                <div className='min-mobile:px-8 md:px-10  px-2   testimonial-item min-w-[270px]   md:min-w-[477px] md:max-w-[477px] md:min-h-[300px] flex flex-col  rounded-11xl bg-mediumslateblue-300  box-border  pt-10 pb-20 '>
                    <div className='flex gap-4 md:gap-6 '>
                        <div className=" ">
                            <img className="w-[85px] h-[70px] " src="testi4.png" />
                        </div>
                        <div>
                            <div className=" text-[1rem] text-black md:text-[1.75rem] font-bold font-['DM Sans'] capitalize ">Sharda University</div>
                           
                        </div>
                    </div>
                    <div className=' pt-[57px] text-[0.88rem] md:text-[1rem]   leading-[1.56rem] text-gray-900 '>I wanted to take this opportunity to thank you for the services you provided over the last year in DVL as well as in Print Journals. It's difficult finding a service provider who meets all of your goals, and you definitely met my expectations. I especially appreciate that you kept us updating time by time, so I always knew what new happening. You are incredibly adaptable and are willing to listen to all of our recommendations.</div>
                </div>


               

            </div>
           
        </div>

      
       {/*}
      <div className="absolute top-[186.94rem] left-[0.06rem] w-[89.94rem] h-[44.81rem] text-[1.75rem] text-black">
        <div className="absolute top-[2.69rem] left-[5.69rem] w-[47.5rem] h-[4.88rem] text-[3.44rem]">
          <div className="absolute top-[0rem] left-[0rem] capitalize font-medium">{`what clients say about us `}</div>
        </div>
        <div className="absolute top-[13.75rem] left-[5.69rem] flex flex-row items-start justify-start">
          <div className="rounded-11xl bg-white shadow-[0px_4px_43px_rgba(168,_168,_168,_0.07)] box-border w-[29.81rem] h-[30.63rem] flex flex-col items-start justify-start py-[2.5rem] pr-[4.56rem] pl-[2.5rem] gap-[2.56rem] border-[1px] border-solid border-gray-1100">
            <div className="w-[19.63rem] flex flex-row items-start justify-start gap-[1.25rem]">
              <img
                className="relative rounded-[50%] w-[5.31rem] h-[5.31rem] object-cover"
                alt=""
                src="/ellipse-80@2x.png"
              />
              <div className="flex-1 flex flex-row items-start justify-start">
                <div className="w-[20rem] flex flex-col items-start justify-start gap-[0.63rem]">
                  <b className="self-stretch relative capitalize">
                    Indian institute of Medical science
                  </b>
                  <div className="relative text-[1rem] capitalize font-medium">
                    Delhi
                  </div>
                </div>
              </div>
            </div>
            <div className="relative text-[1rem] leading-[1.56rem] text-gray-900 inline-block w-[25rem] h-[12.5rem] shrink-0">
              DVL has become an indispensable resource for our medical research
              and education programs. The extensive collection of medical
              literature and easy-to-use platform have greatly enhanced our
              students' learning experience. The subject-specific search tools
              have made research more efficient, and the regular updates keep us
              at the forefront of medical knowledge.
            </div>
          </div>
        </div>
        <div className="absolute top-[13.75rem] left-[66.56rem] flex flex-row items-start justify-start">
          <div className="rounded-11xl bg-mediumslateblue-300 w-[29.81rem] h-[30.63rem] flex flex-col items-start justify-start py-[2.5rem] pr-[4.56rem] pl-[2.5rem] box-border gap-[2.56rem]">
            <div className="w-[19.63rem] flex flex-row items-start justify-start gap-[1.25rem]">
              <img
                className="relative rounded-[50%] w-[5.31rem] h-[5.31rem] object-cover"
                alt=""
                src="/ellipse-80@2x.png"
              />
              <div className="flex-1 flex flex-row items-start justify-start">
                <div className="w-[20rem] flex flex-col items-start justify-start gap-[0.63rem]">
                  <b className="self-stretch relative capitalize">
                    Indian institute of Technology science
                  </b>
                  <div className="relative text-[1rem] capitalize font-medium">
                    Bombay
                  </div>
                </div>
              </div>
            </div>
            <div className="relative text-[1rem] leading-[1.56rem] text-gray-900 inline-block w-[25rem] h-[12.5rem] shrink-0">
              DVL has become an indispensable resource for our medical research
              and education programs. The extensive collection of medical
              literature and easy-to-use platform have greatly enhanced our
              students' learning experience. The subject-specific search tools
              have made research more efficient, and the regular updates keep us
              at the forefront of medical knowledge.
            </div>
          </div>
        </div>
        <div className="absolute top-[13.75rem] left-[36.13rem] flex flex-row items-start justify-start">
          <div className="rounded-11xl bg-mediumslateblue-300 w-[29.81rem] h-[30.63rem] flex flex-col items-start justify-start py-[2.5rem] pr-[4.56rem] pl-[2.5rem] box-border gap-[2.56rem]">
            <div className="w-[19.63rem] flex flex-row items-start justify-start gap-[1.25rem]">
              <img
                className="relative rounded-[50%] w-[5.31rem] h-[5.31rem] object-cover"
                alt=""
                src="/ellipse-80@2x.png"
              />
              <div className="flex-1 flex flex-row items-start justify-start">
                <div className="w-[20rem] flex flex-col items-start justify-start gap-[0.63rem]">
                  <b className="self-stretch relative capitalize">
                    Indian institute of Technology science
                  </b>
                  <div className="relative text-[1rem] capitalize font-medium">
                    Bombay
                  </div>
                </div>
              </div>
            </div>
            <div className="relative text-[1rem] leading-[1.56rem] text-gray-900 inline-block w-[25rem] h-[12.5rem] shrink-0">
              DVL has become an indispensable resource for our medical research
              and education programs. The extensive collection of medical
              literature and easy-to-use platform have greatly enhanced our
              students' learning experience. The subject-specific search tools
              have made research more efficient, and the regular updates keep us
              at the forefront of medical knowledge.
            </div>
          </div>
        </div>
        <img
          className="absolute top-[5.06rem] left-[74.63rem] w-[9.64rem] h-[4.31rem]"
          alt=""
          src="/group-2231.svg"
        />
        <div className="absolute top-[0rem] left-[5.69rem] rounded-51xl box-border w-[5.13rem] h-[1.63rem] flex flex-row items-center justify-center py-[1rem] px-[1.5rem] text-right text-[0.75rem] text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500">
          <div className="relative w-[2.88rem] h-[1.25rem]">
            <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
              Reviews
            </div>
          </div>
        </div>
        <div className="absolute top-[7.88rem] left-[5.63rem] text-[1rem] leading-[2rem] font-medium text-gray-1000 inline-block w-[33rem] h-[1.75rem]">
          Take a look at feedbacks from various Universities
        </div>
      </div>
  {*/}
    </div>
  )
}

export default Testimonial
