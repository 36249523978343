
import {
  HashRouter as Router,
  Routes,
  Route,
 
} from "react-router-dom";

import './App.css'
import OurServices from "./pages/OurServices";
import Home from "./pages/Home";


import FreeTrail from "./pages/FreeTrail";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Login from "./pages/Login";


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/services/:title" element={<OurServices />} />
      <Route path="/" element={<Home />} />
     
       
        
       
        <Route path="/trail" element={<FreeTrail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/login" element={<Login />} />
 
      </Routes>
    </Router>
  );
}

export default App;
