




const Regional = () => {

    


  return (
    <div className="  text-black md:mx-4 mx-2 lg:mx-0 py-20 bg-mediumslateblue-300 rounded-11xl border-[1px] border-solid border-mediumslateblue-200 border-opacity-20 font-dm-sans  mt-20">
        <div className="ml-cust-10 lg:gap-4 gap-8 mr-cust-10 lg:mr-[3%]  grid grid-cols-1 lg:grid-cols-2">

              <div className="md:w-11/12  flex flex-col ">
          
                <div className="mb-6 w-[100px]  flex justify-center bg-white rounded-51xl box-border  text-center    py-1  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
                  <div className="text-center  font-medium text-sm font-['DM Sans']  leading-tight">Regional</div>
                </div>

                <div class="text-[2.4rem]  max-sm:text-[1.8rem] font-[550] tracking-[-1px] md:text-[3rem] capitalize md:font-medium text-black break-words">Our Regional Representatives</div>
 
                <div className="text-[16px]  font-dm-sans  pt-8 leading-loose font-medium text-black text-opacity-70  ">
                Our regional managers and representatives are your local point of contact for all things DVL. They're here to support your needs, answer your questions, and foster collaboration within your region. Connect with them to experience our commitment to personalized service and support tailored to your specific requirements.  </div>
               
                <br/><a style={{color:'#f58634'}} target="_blank" href="https://avags.in/">www.avags.in</a>

             
               
              </div>

            <div className="grid lg:mt-0 md:mt-10 mt-3  md:grid-cols-2 md:gap-4 lg:gap-2 xl:gap-4  gap-5">
                
                    <div className="  flex max-w-[363px] py-2 lg:px-1 xl:px-4  md:px-4 pl-10 max-sm:pl-0  items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className=" flex flex-col gap-8 pl-4 pr-1 py-4">
                            <b className="text-[1.19rem] capitalize text-[#0A1847] ">North Region</b>
                            <div className="flex flex-col gap-2">
                                <div className="text-black text-lg font-medium font-['DM Sans'] capitalize">Seema Ghoshal</div>
                                <div className="text-indigo-950 text-[15px] font-medium font-['DM Sans'] capitalize">Sr. Manager - Sales & Marketing</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="call2.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">+91 9899 8610 71</div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">info@avags.in</div>
                    
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div class="flex max-w-[363px] py-2  lg:px-1 xl:px-4 md:px-4 pl-10 max-sm:pl-0  items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className="flex flex-col gap-8 pl-4 pr-1 py-4">
                            <b class="text-[1.19rem] capitalize text-[#0A1847] ">Tamilnadu</b>
                            <div className="flex flex-col gap-2">
                                <div className="text-black text-lg font-medium font-['DM Sans'] capitalize">R. Gopalakrishnan</div>
                                <div className="text-indigo-950 text-[15px] font-medium font-['DM Sans'] capitalize">General Manager - South India</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="call2.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">+91 9444 1864 00</div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">southindia@avags.in</div>
                    
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div class="flex max-w-[363px] py-2  lg:px-1 xl:px-4   md:px-4 pl-10 max-sm:pl-0  items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className="flex flex-col gap-8 pl-4 pr-1 py-4">
                            <b class="text-[1.19rem] capitalize text-[#0A1847] ">East Region
</b>
                            <div className="flex flex-col gap-2">
                                <div className="text-black text-lg font-medium font-['DM Sans'] capitalize"> Dilip Kumar Sarangi </div>
                                <div className="text-indigo-950 text-[15px] font-medium font-['DM Sans'] capitalize">Regional Manager</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="call2.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">+91 9437 1648 85</div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">dilipks@avags.in</div>
                    
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div class="max-w-[363px]  lg:px-1 xl:px-4 py-2 flex md:px-4 pl-10 max-sm:pl-0 items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className="flex flex-col gap-8 pl-4 pr-1 py-4">
                            <b class="text-[1.19rem] capitalize text-[#0A1847] ">Jammu And Kashmir</b>
                            <div className="flex flex-col gap-2">
                                <div className="text-black text-lg font-medium font-['DM Sans'] capitalize">Farhan Manzoor</div>
                                <div className="text-indigo-950 text-[15px] font-medium font-['DM Sans'] capitalize">Regional Manager</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="call2.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">+91 7006 9554 11</div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">farhan.avags@gmail.com</div>
                    
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div class="max-w-[363px]  lg:px-1 xl:px-4 py-2 flex md:px-4 pl-10 max-sm:pl-0 items-center  rounded-mid bg-white box-border border-custom-2">
                        <div className="flex flex-col gap-8 pl-4 pr-1 py-4">
                            <b class="text-[1.19rem] capitalize text-[#0A1847] ">Andhra/Telangaana</b>
                            <div className="flex flex-col gap-2">
                                <div className="text-black text-lg font-medium font-['DM Sans'] capitalize">Brahamkumar .D</div>
                                <div className="text-indigo-950 text-[15px] font-medium font-['DM Sans'] capitalize">Regional Manager</div>
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="call2.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">+91 9052 1301 01</div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <img className=" " alt="img" src="/group-427318208.svg"/>
                                    <div className=" leading-tight text-[15px] font-medium">ktrsales.avags@gmail.com</div>
                    
                                </div>
                            </div>    
                        </div>
                    </div>
               
                
            </div>
        </div>
        
    </div>
  )
}

export default Regional
