import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import {
  Education,
  AGRICULTURE,
  ARCHITECTURE,
  Forensic,
  Law,
  Management,
  Engineering,
  Physiotherapy,
  DNB,
  Basic,
  Ayush,
  Allied,
  Pharmacy,
  Nursing,
  Dental,
  Medical,
  Homeopathy,
  Masscommmedia,
  HUMANITYANDSOCIALSCIENCE
} from '../../constants';
import LaptopCategories from '../LaptopCategories';
import MobileLogin from '../MobileLogin';
import LaptopLogin from '../LaptopLogin';

const HeaderC = () => {

  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);

  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }


  const both = () => {
    handleExpanded();
    handleServices();
  }

  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }


  return (
    <div>


      <div className="max-mobile:hidden fixed z-[999] block h-[3.63rem]  w-full text-white  bg-midnightblue font-dm-sans  text-[0.81rem]">
        <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
          <div className='pt-2'>
            <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
          </div>
          <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>
            <Link className="no-underline text-white" to='/'><div className='hover:text-green-600 capitalize font-medium hover:cursor-pointer '>Home</div></Link>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='flex items-center hover:cursor-pointer hover:text-green-600 capitalize font-medium'>
              Our Services
              {
                expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
              }
              {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />} </div>
            <Link className='no-underline text-white' to='/#blogs'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></Link>

            <Link className='no-underline text-white' to='/#resources'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></Link>
            <Link className="no-underline text-white" to='/contact'><div className='hover:text-green-600 capitalize font-medium hover:cursor-pointer'>Contact</div></Link>
          </div>



          <div className="flex gap-2 items-center">
            <Link to='/trail' className='no-underline text-white'>
              <button
                className="hover:bg-blue-600 hover:text-white w-full py-2.5  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
              >
                Get Free Trial
              </button>
            </Link>
            <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
              <LaptopLogin />
            </a>
            {/* <Link to='/login' className='no-underline text-white'>
                        <LaptopLogin/>
                        
                        </Link> */}
            {
              Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                <img src="x-close.svg" alt="img" />
              </div>) :

                (<div className="  lg:hidden block text-white">
                  <MenuIcon onClick={handleMobServices} className='' />
                </div>)
            }

          </div>
        </div>
      </div>


      <div className='max-mobile:hidden block w-full md:h-[34.63rem]  h-[800px]'>



        <div className=' text-white absolute w-full md:h-[34.63rem] h-[800px]'>
          <img
            className="hidden md:block w-full md:h-[34.63rem] h-[800px] object-cover"
            alt=""
            src="image-5@2x.png"
          />

          <img
            className="md:hidden block w-full md:h-[34.63rem] h-[800px] object-cover"
            alt=""
            src="contactig.png"
          />

        </div>


        <div className=" absolute [background:linear-gradient(90deg,_#0a1847,_rgba(9,_22,_64,_0.91)_13.07%,_rgba(0,_0,_0,_0))] w-full md:h-[34.63rem] h-[800px]"></div>




        <div className={`text-white     font-dm-sans md:h-[34.63rem]   flex items-center   max-sm:items-end  relative h-full w-4/5 md:pl-20 pl-10 max-sm:pl-4   ]`}>



          <div className={`mb-20  flex flex-col ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}`}>
            <div className="mb-16 flex gap-2 text-white text-[13px] font-medium font-['DM Sans'] capitalize">
              <Link to='/' className='no-underline text-white'><div class="hover:opacity-[1] hover:underline hover:text-green-600  capitalize font-medium inline-block  opacity-[0.2]">Home</div></Link>
              <div class=" capitalize font-medium inline-block ">/</div>
              <div class="capitalize font-medium">Contact Us</div>
            </div>
            <b className=" font-bold xl:text-[3.75rem]  lg:text-[3rem] md:text-[3rem] text-[3rem] max-medium:text-[3rem] max-sm:text-[2rem] md:leading-[4.81rem] tracking-[-1px] capitalize ">
              We’d like to hear more about you
            </b>




            <div className="md:text-[1rem] textclr-cust   mt-3 xl:mt-6 md:w-4/5  md:leading-[2rem] leading-[1.8rem] font-medium  ">
              Let us handle all your queries , Fill up the form below and our representative will contact you very soon
            </div>









          </div>


        </div>

      </div>





      <div className={` fixed z-[999] top-[5rem] font-dm-sans text-white  flex   gap-3 justify-center w-full  overflow-x-hidden md:max-h-[936px] max-h-[600px] ${services ? 'block' : 'hidden'}`}>
        <LaptopCategories handleServices={handleServices} />

      </div>




      <div className='hidden max-mobile:block' style={{ position: 'relative' }}>
        <img
          className="w-full h-full object-cover"
          alt=""
          src="contactig.png"
        />
        <img
          className='object-cover absolute bottom-0 left-0 w-full  h-full '
          alt='ig'
          src="rectangle-516.svg"
        />
        <div className='bgcust2 fixed z-[999] h-[4rem]  w-full top-0 backdrop-filter-cust' style={{ zIndex: 1 }}>
          <div className='flex h-full justify-between items-center pl-[4%] pr-[4%] '>
            <div className='pt-2'>
              <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
            </div>
            <div className="flex gap-2 items-center">
              <Link to='/trail' className='no-underline text-white'>
                <button
                  className="w-full py-2  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                >
                  Get Free Trial
                </button>
              </Link>
              <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
                <LaptopLogin />
              </a>
              {/* <Link to='/login' className='no-underline text-white'>
                <LaptopLogin />
              </Link> */}
              {
                Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                  <img src="x-close.svg" alt="img" />
                </div>) :

                  (<div className="  lg:hidden block text-white">
                    <MenuIcon onClick={handleMobServices} className='' />
                  </div>)
              }
            </div>
          </div>
        </div>

        <div className=' pl-6 font-dm-sans absolute text-black  top-[12%] left-0'>
          <div className={` flex gap-2  text-[13px] font-medium font-['DM Sans'] capitalize ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}`}>
            <Link to='/' className='no-underline text-black' ><div class="hover:opacity-[1] hover:underline hover:text-green-600   capitalize font-medium text-[0.812rem]  ">Home</div></Link>
            <div class=" capitalize text-black font-medium">/</div>
            <div class="capitalize text-black  font-medium text-[0.812rem]">Contact</div>
          </div>
        </div>

        <div className={` absolute text-white  bottom-[10%] left-0 ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}`}>
          <div className='pl-6 flex flex-col'>
            <b class="w-10/12 max-sm:w-full tracking-[-1px] inline-block text-[3rem] font-dm-sans">We’d like to hear more about you</b>
            <div class=" mt-6 font-dm-sans pr-[4%] text-[1rem] leading-[2rem] font-medium text-custom inline-block ">Let us handle all your queries , Fill up the form below and our representative will contact you very soon </div>
          </div>
        </div>

      </div>


      {
        Mobservices &&

        (<MobileLogin expanded={expanded} handleExpanded={handleExpanded} handleMobServices={handleMobServices} />)
      }



    </div>
  )
}

export default HeaderC
