

const Benefits = () => {
    return (
      <div className='py-20  text-white font-dm-sans'>
          <div className="mx-4 rounded-51xl box-border lg:ml-cust-10 md:ml-cust-5  md:mr-cust-10 inline-block  text-center   px-6 py-2  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
              <div className="text-center  font-medium  font-['DM Sans']  leading-tight">Benefits</div>
          </div>
          <div className=" pt-8 lg:ml-cust-10 md:ml-cust-5 md:mr-cust-10 grid grid-cols-1 md:grid-cols-2 gap-16">
  
  
              <div className="ml-cust-5 mr-cust-5  md:ml-0 flex flex-col gap-4">
                

                <div className=" xl:text-[3.44rem] text-[3rem] md:text-[2rem]     max-sm:text-[2rem] font-[550]  tracking-[-1px]  capitalize md:font-bold xl:font-medium   text-black">We believe in Giving Value to learners</div>
                  
                <div className="text-[1rem]  w-4/5 pt-5 pl-1 leading-[2rem] font-medium text-gray-1000 ">
                Dvirtual Library (DVL) is a leading digital resource hub dedicated to empowering excellence in medical education and research.
                </div>
  
                <div className="flex ">
                    <img className="-ml-[6%] w-full max-h-[501px] max-w-[632px]" alt="img" src="/rectangle-1971@2x.png" />
                </div>
  
              </div>
  
              <div className="flex flex-col md:gap-6  gap-8">

              <div className="flex  items-start px-2">
                    <img className="xl:w-auto xl:h-auto w-[97px]  h-[97px]" src="majesticonsdollarcircle.svg" alt="img" />
                    <div className="text-black">
                      <div className="capitalize max-sm:text-[18px] xl:font-medium font-bold   xl:text-[2rem] text-[1.6rem]  md:text-[1.3rem] md:font-bold">Easy Search</div>
                      <div className="max-sm:text-[14px] leading-[1.8rem]  md:mt-3 xl:mt-1 mt-3 xl:text-[1rem] md:text-[14px] xl:leading-[2rem]  font-medium text-gray-1000">DVL provides an easy-to-use interface and powerful search functionality. Users can effectively search and retrieve information with Sub Subjects. </div>
                    </div>
                </div>
                <div className="flex  items-start px-2">
                    <img className="xl:w-auto xl:h-auto w-[97px]  h-[97px]" src="ua.svg" alt="img" />
                    <div className="text-black">
                      <div className="capitalize max-sm:text-[18px] xl:font-medium font-bold   xl:text-[2rem] text-[1.6rem]  md:text-[1.3rem] md:font-bold">Unlimited Access</div>
                      <div className="max-sm:text-[14px] leading-[1.8rem]  md:mt-3 xl:mt-1 mt-3 xl:text-[1rem] md:text-[14px] xl:leading-[2rem]  font-medium text-gray-1000">Unlimited simultaneous user access to all authentic users, 24*7, 365 days an year.</div>
                    </div>
                </div>
                <div className="flex  items-start px-2">
                    <img className="xl:w-auto xl:h-auto w-[97px]  h-[97px]" src="sip.svg" alt="img" />
                    <div className="text-black">
                      <div className="capitalize max-sm:text-[18px] xl:font-medium font-bold   xl:text-[2rem] text-[1.6rem]  md:text-[1.3rem] md:font-bold">Secured IP Based Access</div>
                      <div className="max-sm:text-[14px] leading-[1.8rem] md:mt-3 xl:mt-1 mt-3 xl:text-[1rem] md:text-[14px] xl:leading-[2rem]  font-medium text-gray-1000"> Secured IP-based access for eLibrary offers enhanced security and user authentication, ensuring that only authorized users with specific IP addresses can access the digital resources.</div>
                    </div>
                </div>
                <div className="flex  items-start px-2">
                    <img className="xl:w-auto xl:h-auto w-[97px]  h-[97px]" src="reg.svg" alt="img" />
                    <div className="text-black">
                      <div className="capitalize max-sm:text-[18px] xl:font-medium font-bold   xl:text-[2rem] text-[1.6rem]  md:text-[1.3rem] md:font-bold">Regular Journal Updation</div>
                      <div className="max-sm:text-[14px] leading-[1.8rem] md:mt-3 xl:mt-1 mt-3 xl:text-[1rem] md:text-[14px] xl:leading-[2rem] font-medium text-gray-1000">Regular journal updating benefits eLibrary by ensuring that it remains a current and reliable source of information for users, facilitating access to the latest research and knowledge in various fields.</div>
                    </div>
                </div>
              </div>
  
  
              
            
          </div>    
  
  
  {/*}
          <div className="absolute left-[0rem] w-[90rem] h-[28.19rem] text-[2.94rem] text-gray-300">
              <div className="absolute top-[6.5rem] left-[46.5rem] text-[3.44rem] tracking-[-1px] capitalize font-medium text-black inline-block w-[36rem]">
              Our resource Bank
              </div>
              <div className="absolute top-[12.25rem] left-[46.5rem] text-[1rem] leading-[2rem] font-medium text-gray-1000 inline-block w-[37.56rem]">
              Our commitment to excellence drives us to add new journals, e-books,
              articles, and videos every month, ensuring that our users always have
              access to the latest and most valuable resources."
              </div>
              <div className="absolute top-[4.38rem] left-[46.5rem] rounded-51xl box-border w-[5.13rem] h-[1.63rem] flex flex-row items-center justify-center py-[1rem] px-[1.5rem] text-right text-[0.75rem] text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500">
                  <div className="relative w-[3.38rem] h-[1.25rem]">
                      <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                      Statistics
                      </div>
                  </div>
              </div>
              <div className="absolute top-[7.81rem] left-[6.63rem] w-[19.81rem] h-[5.31rem] text-mediumslateblue-200">
                  <div className="absolute top-[0rem] left-[6.38rem] w-[13.44rem] h-[5.31rem]">
                      <div className="text-blue-600 text-[47px] font-bold font-['DM Sans'] capitalize">25k+</div>
                      <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] font-medium inline-block w-[13.44rem]">
                      E-Journals
                      </div>
                  </div>
                  <img
                      className="absolute h-[108.24%] w-[29.02%] top-[2.35%] right-[73.82%] bottom-[-10.59%] left-[-2.84%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/group-2265.svg"
                  />
              </div>
              <div className="absolute top-[17.31rem] left-[6.63rem] w-[15.75rem] h-[5.31rem]">
                  <div className="absolute top-[0rem] left-[6.06rem] w-[9.69rem] h-[5.31rem]">
                  <b className="absolute top-[0rem] left-[0rem] capitalize">1000+</b>
                  <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] capitalize font-medium text-gray-1000 inline-block w-[9.69rem]">
                  Ebooks
                  </div>
                  </div>
                  <img
                      className="absolute h-[108.24%] w-[36.51%] top-[3.53%] right-[67.06%] bottom-[-11.76%] left-[-3.57%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/group-2266.svg"
                  />
              </div>
              <div className="absolute top-[7.88rem] left-[26.19rem] w-[16.19rem] h-[5.31rem]">
                  <div className="absolute top-[0rem] left-[6.5rem] w-[9.69rem] h-[5.31rem]">
                  <b className="absolute top-[0rem] left-[0rem] capitalize">300+</b>
                  <div className="absolute top-[3.81rem] left-[0rem] text-[1rem] leading-[1.5rem] font-medium text-gray-1000 inline-block w-[9.69rem]">
                  Publishers
                  </div>
                  </div>
                  <img
                      className="absolute h-[108.24%] w-[35.52%] top-[3.53%] right-[67.95%] bottom-[-11.76%] left-[-3.47%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/group-2244.svg"
                  />
              </div>
              <div className="absolute top-[17.31rem] left-[26.19rem] w-[17.06rem] h-[5.31rem]">
                  <b className="absolute top-[0rem] left-[6.5rem] capitalize">10k</b>
                  <div className="absolute top-[3.81rem] left-[6.5rem] text-[1rem] leading-[1.5rem] capitalize font-medium text-gray-1000 inline-block w-[10.56rem]">
                      Valuable resources
                  </div>
                  <img
                      className="absolute h-[108.24%] w-[33.7%] top-[3.53%] right-[69.6%] bottom-[-11.77%] left-[-3.3%] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/group-2243.svg"
                  />
              </div>
          </div>
    {*/}
      </div>
    )
  }
  
  export default Benefits
  
  
  
  
  
  
  {/*
    <div className="absolute top-[235.5rem] left-[calc(50%_-_601px)] flex flex-col items-center justify-center text-center">
          <div className="relative rounded-7xl bg-mediumslateblue-100 w-[75.13rem] h-[21.63rem] overflow-hidden shrink-0">
            <img
              className="absolute top-[0rem] left-[12.38rem] w-[49.48rem] h-[29.86rem]"
              alt=""
              src="/group-2234.svg"
            />
            <div className="absolute top-[11.13rem] left-[19.13rem] leading-[2rem] font-medium inline-block w-[38rem]">
              Start your journey of getting study resource at your fingertips in
              digital system
            </div>
            <div className="absolute top-[3.44rem] left-[17.94rem] text-[2.5rem] tracking-[-1px] capitalize font-medium inline-block w-[38.38rem]">
              <p className="m-0">Get started With A</p>
              <p className="m-0"> free trial</p>
            </div>
            <div className="absolute top-[14.94rem] left-[33.06rem] rounded-51xl bg-white flex flex-row items-center justify-start py-[1rem] px-[1.5rem] text-right text-mediumslateblue-200">
              <div className="relative w-[6.03rem] h-[1.25rem]">
                <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                  Book Now
                </div>
                <img
                  className="absolute top-[0.31rem] left-[5.31rem] w-[0.72rem] h-[0.63rem]"
                  alt=""
                  src="/vector2.svg"
                />
              </div>
            </div>
          </div>
  </div> */}
  
  
  