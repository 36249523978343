import React from 'react'

const Category = ({name}) => {
  return (
    <>
        <button className="hover:bg-white hover:text-blue-600 hover:border-blue-600 px-3 py-2  capitalize text-[12px] md:text-[14px] lg:text-[1.25rem] font-[400px] font-dm-sans w-full md:py-3 md:px-6   bg-mediumslateblue-200  text-white   font-[DM Sans] leading-tight border border-mediumslateblue-500  rounded-11xl ">
        {name}
        </button>
    </>
  )
}

export default Category
