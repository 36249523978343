

const AboutUsSection = ({title,resources,about}) => {
  const [eJournals, eBooks , eVideos , OtherResources] = resources;
  
  //console.log('ab', aboutdata);

  return (
    <div className='pt-20 mb-24 text-white font-dm-sans'>
        <div className="  rounded-51xl box-border lg:ml-cust-10 ml-cust-5 mr-cust-10 inline-block  text-center   px-6 py-2  text-mediumslateblue-200 border-[1px] border-solid border-mediumslateblue-500 ">
            <div className="text-center  font-medium  font-['DM Sans']  leading-tight">About Us</div>
        </div>
        <div className="pt-8 lg:ml-cust-10  ml-cust-5   mr-cust-5 lg:mr-cust-10 grid grid-cols-1 lg:grid-cols-2 gap-16">


            <div className=" flex flex-col ">
                
                <div className="text-[2.44rem] max-sm:text-[2rem]  xl:text-[3.44rem] font-bold md:text-[2.2rem]   capitalize xl:font-medium text-black  ">
                    {title}
                </div>
                <div className="text-[1rem] pt-5 pl-1 leading-loose font-medium text-gray-1000 ">
                {about}  </div>

               

                <div className='mt-14  '>
                    <div className="bg-grey-500  flex flex-col gap-8 ">
                    
                        <div className='min-mobile:flex-row flex flex-col gap-4   '>
                            <div className=' w-1/2 flex items-center'>
                                <img
                                className="  "
                                alt=""
                                src="/group-2265.svg"
                                />
                                <div className=" hover:text-blue-600 text-black  pl-2 text-[47px]  md:text-[2rem] xl:text-[47px] font-bold font-['DM Sans'] ">{eJournals}+
                                    <div className="hover:text-blue-600  text-black lg:text-[1rem]  text-[1rem] leading-[1.5rem] font-medium  ">
                                    eJournals
                                    </div>
                                </div>      
                            </div>

                            <div className='  w-1/2  flex items-center'>
                                <img
                                className=" "
                                alt=""
                                src="/group-2244.svg"
                                />
                                <div className=" hover:text-blue-600 pl-2 text-[47px] md:text-[2rem] xl:text-[47px] text-black font-bold font-['DM Sans'] ">{eVideos}+
                                    <div className=" hover:text-blue-600 text-black lg:text-[1rem]  text-[1rem] leading-[1.5rem] font-medium  ">
                                    eVideos
                                    </div>
                                </div>      
                            </div>
                        </div>


                        <div className='flex gap-4  min-mobile:flex-row  flex-col '>
                            <div className=' w-1/2 flex items-center'>
                                
                                <img
                                className=" "
                                alt=""
                                src="/group-2243.svg"
                                />
                                <div className="hover:text-blue-600 pl-2 text-[47px] md:text-[2rem] xl:text-[47px] text-black font-bold font-['DM Sans'] ">{OtherResources || 100}+
                                    <div className="hover:text-blue-600  text-black lg:text-[1rem]  text-[1rem]   lg:leading-[1.5rem] font-medium  ">
                                    Other eresources
                                    </div>
                                </div>      
                                
                            
                            </div>

                            <div className=' w-1/2 flex  items-center'>
                                <img
                                className="   "
                                alt=""
                                src="/group-2266.svg"
                                />
                                <div className="pl-2 hover:text-blue-600 text-[47px] md:text-[2rem] xl:text-[47px] text-black font-bold font-['DM Sans'] ">{eBooks}+
                                    <div className=" hover:text-blue-600 text-black lg:text-[1rem]  text-[1rem] lg:leading-[1.5rem] font-medium  ">
                                    eBooks
                                    </div>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="  hidden md:medical-img ">
            
            </div>


            
          
        </div>    


    </div>
  )
}

export default AboutUsSection






{/*
  <div className="absolute top-[235.5rem] left-[calc(50%_-_601px)] flex flex-col items-center justify-center text-center">
        <div className="relative rounded-7xl bg-mediumslateblue-100 w-[75.13rem] h-[21.63rem] overflow-hidden shrink-0">
          <img
            className="absolute top-[0rem] left-[12.38rem] w-[49.48rem] h-[29.86rem]"
            alt=""
            src="/group-2234.svg"
          />
          <div className="absolute top-[11.13rem] left-[19.13rem] leading-[2rem] font-medium inline-block w-[38rem]">
            Start your journey of getting study resource at your fingertips in
            digital system
          </div>
          <div className="absolute top-[3.44rem] left-[17.94rem] text-[2.5rem] tracking-[-1px] capitalize font-medium inline-block w-[38.38rem]">
            <p className="m-0">Get started With A</p>
            <p className="m-0"> free trial</p>
          </div>
          <div className="absolute top-[14.94rem] left-[33.06rem] rounded-51xl bg-white flex flex-row items-center justify-start py-[1rem] px-[1.5rem] text-right text-mediumslateblue-200">
            <div className="relative w-[6.03rem] h-[1.25rem]">
              <div className="absolute top-[0rem] left-[0rem] leading-[1.25rem] capitalize font-medium">
                Book Now
              </div>
              <img
                className="absolute top-[0.31rem] left-[5.31rem] w-[0.72rem] h-[0.63rem]"
                alt=""
                src="/vector2.svg"
              />
            </div>
          </div>
        </div>
</div> */}


