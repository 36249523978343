import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useEffect } from 'react';
import { scroller } from 'react-scroll';

import LaptopCategories from '../LaptopCategories';
import MobileCategories from '../MobileCategories';

import LaptopLogin from '../LaptopLogin';

const NavbarCust = () => {

  const [services, setServices] = useState(false);
  const [expanded, setexpanded] = useState(false);

  const [videoplayed, setVideo] = useState(false);

  const both = () => {
    handleExpanded();
    handleServices();
  }

  const handleExpanded = () => {
    setexpanded(!expanded);
  }

  const handleServices = () => {

    console.log(' clicked');
    setServices(!services);

  }

  const [Mobservices, setMobServices] = useState(false);

  const handleMobServices = () => {

    console.log('Mob clicked');
    setMobServices(!Mobservices);

  }

  useEffect(() => {
    const hash = window.location.hash;
    console.log(hash);

    if (hash === '#/#blogs') {

      scroller.scrollTo('blogs', {
        smooth: true,
        duration: 500,
        offset: -50,
      });
    }
    else if (hash === '#/#resources') {
      scroller.scrollTo('resources', {
        smooth: true,
        duration: 500,
        offset: -50,
      });
    }
  }, []);
  const [backgroundColor, setBackgroundColor] = useState('gray'); // Initial background color


  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;

      // Change the background color when scroll position reaches 1000px
      if (currentPosition > 300) {
        setBackgroundColor('black');
      } else {
        setBackgroundColor('gray');
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>

      <div className='w-full h-[58rem]  relative font-dm-sans text-white'>

        {/*
          videoplayed===false && ( <div className='absolute w-full h-full '>
          <img
          className=" w-full h-full object-cover"
          alt=""
          src="/image-2@2x.png"
          />
      </div>
)
  */}
        {

          (<div className='absolute w-full h-full z-0'><video
            className="w-full object-cover h-full absolute"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="https://assets.mixkit.co/videos/preview/mixkit-student-doing-research-in-university-library-with-books-and-laptop-8826-large.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>)
        }

        {/*videoplayed===false && (<div className="absolute   bg-gray-200  w-full h-full opacity-[0.5]" />)*/}




        <div className={`pr-1 ${services ? 'blur-text' : ''} ${Mobservices ? 'blur-text' : ''}   relative h-full   w-full flex justify-center items-center `}>

          <div className='max-w-[800px]  text-center '>
            <b className="px-1 font-bold lg:text-[5rem] md:text-[3.5rem] text-[4rem]  md:leading-[rem]  lg:leading-[6.16rem] capitalize ">
              Discover, Learn, and Innovate with DVL
            </b>
            <div className="min-mobile:pl-28 px-10  md:pl-32 mt-4 min-mobile:w-3/5 leading-[2rem] font-medium  ">
              Your trusted source for cutting-edge technical medical research and
              more.
            </div>

            {/* videoplayed===false && ( <div className="flex max-sm:flex-wrap  justify-center md:w-4/5 mt-8  gap-2 min-mobile:pl-10 pl-0">
              <button className="hover:text-purple-300 max-sm:text-[12px]  whitespace-nowrap py-3 px-4 text-center font-dm-sans  bg-mediumslateblue-200 text-white  font-medium font-[DM Sans] leading-tight   border border-mediumslateblue-500 rounded-11xl "
              >Lets Start</button>
              <button onClick={() => setVideo(true)} className=" hover:bg-blue-600 max-sm:text-[12px]   flex bg-transparent  font-dm-sans gap-1 items-center py-3 px-4 whitespace-nowrap  text-white  font-medium font-[DM Sans] leading-tight border-[1px] border-solid border-gray-600  rounded-11xl ">
                <img
                  className=" "
                  alt="logo"
                  src="/heroiconssolidplay.svg"
                />
                <div  className=''>  Watch Video </div>
              </button>   
      </div>) */}

          </div>


        </div>
        <div className={`  top-0 left-0 fixed z-[999] w-full bg-${backgroundColor} min-mobile:[backdrop-filter:blur(12px)] [backdrop-filter:blur(8px)] md:h-[3.63rem] h-[4rem] min-mobile:mt-0 text-[0.81rem]`}>
          <div className='flex h-full items-center lg:pl-4 lg:pr-4 xl:pl-cust-7 pl-cust-7 pr-cust-7 xl:pr-cust-7 justify-between'>
            <div className='pt-2'>
              <img className=' max-w-[104px] h-9 w-10/12' src="/image-4@2x.png" alt="img" />
            </div>
            <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} className='lg:flex hidden  justify-between gap-16'>

              <Link className='text-white no-underline' to="/"><div className='hover:text-green-400    capitalize font-medium hover:cursor-pointer '>Home</div></Link>

              <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }} onClick={both} className='hover:cursor-pointer hover:text-green-400 flex items-center  capitalize font-medium'>
                Our Services
                {
                  expanded && <img className='ml-1' src='uparrow.svg' alt='img' />
                }
                {expanded === false && <img className='ml-1  ' src="Group-22135.svg" alt="img" />}
              </div>
              <ScrollLink
                to="blogs"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className="hover:text-green-400 text-white no-underline capitalize font-medium hover:cursor-pointer"
              >   <div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>News & Blogs</div></ScrollLink>
              <ScrollLink
                to="resources"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className="hover:text-green-400 text-white no-underline capitalize font-medium hover:cursor-pointer"
              ><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Resources</div></ScrollLink>
              <Link className='text-white no-underline' to='/contact'><div className='hover:text-green-400  text-white no-underline capitalize font-medium hover:cursor-pointer'>Contact</div></Link>
            </div>
            <div className="flex gap-2 items-center">
              <Link to='/trail' className='no-underline text-white'>
                <button
                  className="hover:bg-blue-600 hover:text-white  w-full py-2.5  text-[0.8rem] px-6 capitalize whitespace-nowrap bg-white max-w-[214px] text-blue-600     rounded-11xl "
                >
                  Get Free Trial
                </button>
              </Link>
              <a href='https://portal.dvlonline.com/' target='_blank' className='no-underline text-white'>
                <LaptopLogin />
              </a>
              {/* <Link to='/login' className='no-underline text-white'>
                        <LaptopLogin />
                      
                        </Link> */}
              {
                Mobservices ? (<div onClick={handleMobServices} className=" text-center lg:hidden block text-white">
                  <img src="x-close.svg" alt="img" />
                </div>) :

                  (<div className="  lg:hidden block text-white">
                    <MenuIcon onClick={handleMobServices} className='' />
                  </div>)
              }

            </div>
          </div>
        </div>


        <div className={` top-[5rem]  flex fixed z-[999]  gap-3 justify-center w-full   overflow-x-hidden   ${services ? 'block' : 'hidden'}`}>
          <LaptopCategories handleServices={handleServices} />
        </div>

        {
          Mobservices &&
          (<div className='max-h-[80vh] overflow-y-scroll z-[999] fixed   max-mobile:top-[4rem] text-white font-dm-sans transition-all duration-500 ease-in   left-0 right-0 top-[3.63rem]   gap-7 flex py-20 px-10 flex-col lg:hidden bg-[#0A1847] '>

            <div className=' w-full'>
              <Link className='no-underline text-white' to='/'><div className='hover:text-green-600 hover:cursor-pointer capitalize font-dm-sans font-medium'>Home</div></Link>
              <img className='mt-9 w-full' src="Line-2.svg" alt="ig" />
            </div>

            <div>
              <div onClick={handleExpanded} className=' inline-flex gap-2 items-center '>
                <div className='z-[999] hover:text-green-600 hover:cursor-pointer capitalize font-dm-sans font-medium '>Our Services
                  {
                    expanded && <img className='ml-2' src='Group-22135.svg' alt='img' />
                  }
                  {
                    expanded && (
                      <div className='max-h-[80vh] overflow-y-scroll'>
                        <MobileCategories handleMobServices={handleMobServices} />
                      </div>
                    )
                  }

                </div>
                {expanded === false && <img src="Group-2213.svg" alt="img" />}
              </div>

              <div className='flex flex-col'>
                <div></div>
              </div>
              <img src="Line-2.svg" className='mt-9 w-full' alt="" />
            </div>

            <div>
              <ScrollLink
                to="blogs"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className="hover:text-green-400 text-white no-underline capitalize font-medium hover:cursor-pointer"
              > <div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>News & Blogs </div></ScrollLink>
              <img src="Line-2.svg" className=' mt-9 w-full' alt="img" />
            </div>


            <div>
              <ScrollLink
                to="resources"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className="hover:text-green-400 text-white no-underline capitalize font-medium hover:cursor-pointer"
              > <div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>Resources </div></ScrollLink>
              <img src="Line-2.svg" className=' mt-9 w-full' alt="img" />
            </div>


            <div>
              <Link className='no-underline text-white' to='/contact'><div className=' capitalize font-dm-sans font-medium hover:text-green-600 hover:cursor-pointer'>Contact Us</div></Link>
              <img src="Line-2.svg" className=' mt-9 w-full' alt="" />
            </div>
            <Link to='/login' className='no-underline text-white'>
              <button
                className="hover:text-green-400  w-full py-3 px-8   capitalize whitespace-nowrap bg-blue-600 max-w-[114px] text-white     rounded-11xl "
              >
                Login
              </button>
            </Link>
          </div>)
        }

      </div>
    </>
  )
}

export default NavbarCust



